import styled from "styled-components";
import { mainFontColor, secondaryColor } from "../../../utils/styleVariables";

export const ProjectItemContainer = styled.div`
  margin-bottom: 2.4rem;
`;

export const ProjectItemTitle = styled.div`
  display: flex;
  align-items: center;

  h3 {
    margin-left: 2.4rem;
    font-size: 1.8rem;
    color: ${mainFontColor};
  }

  svg {
    fill: ${secondaryColor};
    cursor: pointer;
  }

  @media (max-width: 26em) {
    h3 {
      margin-left: 1.2rem;
      font-size: 1.6rem;
    }
  }
`;

export const ImageContainer = styled.div`
  margin-bottom: 2.4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  img {
    max-height: 32rem;
    max-width: 32rem;
    margin: 2.4rem;
  }

  @media (max-width: 66em) {
    img {
      margin: 2rem;
      max-height: 30rem;
      max-width: 30rem;
    }
  }

  @media (max-width: 48em) {
    img {
      max-height: 28rem;
      max-width: 28rem;
    }
  }

  @media (max-width: 24em) {
    img {
      max-height: 24rem;
      max-width: 24rem;
    }
  }
`;
