import styled from "styled-components";
import { mainFontColor, linkColor } from "../../utils/styleVariables";

export const CookiesPolicyContainer = styled.div`
  max-width: 136.6rem;
  margin: 0 auto;
`;

export const SectionContainer = styled.main`
  max-width: 80rem;
  margin: 1.2rem auto 9.6rem auto;

  display: flex;
  flex-direction: column;

  line-height: 1.2;
  letter-spacing: 0.05rem;
  font-size: 1.6rem;
  color: ${mainFontColor};

  h4,
  h3 {
    align-self: flex-start;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
  }

  h4:nth-child(3),
  h3 {
    margin-top: 2.4rem;
  }

  p {
    margin-bottom: 1.2rem;
    text-align: justify;
  }

  ul {
    line-height: 1.2;
    letter-spacing: 0.05rem;
    font-size: 1.6rem;
    color: ${mainFontColor};
    margin-bottom: 1.2rem;
  }

  li {
    margin-bottom: 1.2rem;
  }

  li a {
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 500;
    color: ${linkColor};
  }

  @media (max-width: 56em) {
    margin: 2.4rem 2.4rem 9.6rem 2.4rem;
  }

  @media (max-width: 26em) {
    margin: 1.2rem 2.4rem 9.6rem 2.4rem;
  }
`;

export const CookiesTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 0.1rem solid ${mainFontColor};
  border-radius: 0.6rem;
  padding: 2rem;
  margin-bottom: 1.2rem;
`;

export const CookiesTableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #212529;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  padding: 2rem 0;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.3);

  h5 {
    width: 20%;
    font-size: 1.4rem;
    margin: 0 1rem;
  }

  h5:nth-child(3) {
    width: 60%;
  }

  @media (max-width: 34em) {
    h5 {
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  @media (max-width: 26em) {
    h5 {
      width: 30%;
    }

    h5:nth-child(3) {
      width: 40%;
    }
  }
`;

export const CookiesTableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.3);
  padding: 2.4rem 0;

  p {
    font-size: 1.6rem;
    width: 60%;
    margin: 0 1rem;
  }
  span {
    text-align: center;
    font-size: 1.6rem;
    width: 20%;
    margin: 0 1rem;
  }

  @media (max-width: 34em) {
    span,
    p {
      font-size: 1.4rem;
      margin: 0 0.8rem;
    }
  }

  @media (max-width: 26em) {
    p {
      font-size: 1.2rem;
      width: 50%;
      word-break: break-word;
      text-align: center;
    }

    span {
      font-size: 1.2rem;
      width: 25%;
      word-break: break-word;
    }
  }
`;
