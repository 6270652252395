import styled from "styled-components";
import { secondaryColor, mainColorTint } from "../../../utils/styleVariables";

export const OurServicesContainer = styled.div`
  margin: 0 1.8rem;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 28rem;
  height: 38rem;
  // background-color: #ffffff;
  border-radius: 0 0 0.4rem 0.4rem;
  box-shadow: 0 0.1rem 1.8rem ${mainColorTint};

  img {
    height: 20rem;
    border-radius: 0.4rem 0.4rem 0 0;
    margin-bottom: 1.6rem;
  }
  @media (max-width: 72em) {
    margin: 1.2rem 1.8rem;
  }

  @media (max-width: 30em) {
    width: 24rem;
    height: 34em;

    img {
      height: 16rem;
      border-radius: 0.4rem 0.4rem 0 0;
      margin-bottom: 1rem;
    }
  }
`;

export const IconContainer = styled.span`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 19rem;
  left: 50%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;

  border-radius: 50%;
  background-color: ${secondaryColor};

  @media (max-width: 30em) {
    top: 15.5rem;
    left: 50%;
    width: 4.8rem;
    height: 4.8rem;
  }
`;
