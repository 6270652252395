import styled from "styled-components";

import {
  topBarBgColor,
  secondaryFontColor,
} from "../../../utils/styleVariables";

export const MainContainer = styled.div`
  width: 100%;
  background-color: ${topBarBgColor};
`;

export const TopBarContainer = styled.div`
  max-width: 136.6rem;
  margin: 0 auto;
  padding: 1rem 3.6rem 1rem 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 53em) {
    padding: 1rem;
  }
`;

export const InfoValue = styled.div`
  display: flex;
  justify-content: center;

  margin-right: 2.4rem;
  margin-bottom: 0.8rem;
  color: ${secondaryFontColor};
  font-size: 1.2rem;

  @media (max-width: 24em) {
    margin-right: 1.8rem;
  }

  a {
    text-decoration: none;
  }
  a,
  span {
    padding-left: 0.4rem;
    font-weight: 600;
    color: ${secondaryFontColor};
  }
`;
