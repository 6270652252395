import styled from "styled-components";

import {
  footerBgColor,
  secondaryFontColor,
  secondaryColor,
} from "../../utils/styleVariables";

export const MainContainer = styled.footer`
  width: 100%;
  display: flex;

  justify-content: center;
  background-color: ${footerBgColor};
`;

export const FooterContainer = styled.div`
  max-width: 136.6rem;
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 2.4rem;
  color: ${secondaryFontColor};

  @media (max-width: 23em) {
    padding: 1.2rem;
  }
`;

export const LogoContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2.4rem;

  img {
    height: 12rem;
    margin-bottom: 2.4rem;
  }

  @media (max-width: 55.3em) {
    margin: 2.4rem 0 0 0;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  font-size: 1.4rem;

  h3 {
    margin-bottom: 1.8rem;
    font-size: 1.6rem;
  }
  svg {
    fill: ${secondaryFontColor};
    margin-right: 1.2rem;
  }

  @media (max-width: 23em) {
    flex-direction: column;
  }
`;

export const WhereToFindUs = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 3.6rem;

  @media (max-width: 23em) {
    margin: 2.4rem 0;
  }
`;

export const WhereToFindUsItem = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;

  a {
    text-decoration: none;
  }

  a,
  span {
    font-size: 1.4rem;
    color: ${secondaryFontColor};
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoItem = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;

  a {
    font-size: 1.4rem;
    text-decoration: none;
    color: ${secondaryFontColor};
  }
`;

export const Bullet = styled.span`
  display: block;
  padding: 0.2rem;
  margin-right: 1.2rem;
  background-color: ${secondaryColor};
`;

export const Copyright = styled.div`
  width: 100%;
  color: ${secondaryFontColor};
  text-align: center;
  font-size: 1.2rem;
  margin-top: 3.6rem;
  a {
    padding-left: 0.2rem;
    text-decoration: none;
    color: ${secondaryFontColor};
  }
`;
