// React
import { createContext, useState, useEffect } from "react";

// Utils
import { debounce } from "../utils/utils";
// Screen dimensions size context
export const ScreenDimensionsContext = createContext();

export const ScreenDimensionsProvider = ({ children }) => {
  const [screenDimensions, setScreenDimensions] = useState({ ...window });

  // Detect screen dimensions change
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setScreenDimensions({ ...window });
    }, 500);

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  const value = { screenDimensions, setScreenDimensions };

  return (
    <ScreenDimensionsContext.Provider value={value}>
      {children}
    </ScreenDimensionsContext.Provider>
  );
};
