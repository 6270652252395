import styled from "styled-components";
import { mainColor, mainFontColor } from "../../utils/styleVariables";

export const TriangleDivider = styled.div`
  max-width: 136.6rem;
  width: 100%;
  height: 0.2rem;
  margin: 0 auto;
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  background-color: ${mainColor};
  opacity: 0.8;

  @media (max-width: 56em) {
    margin-top: 1.2rem;
  }

  @media (max-width: 33em) {
    display: none;
  }
`;

export const Title = styled.h1`
  margin-top: 3.6rem;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
  color: ${mainFontColor};
  letter-spacing: 0.075rem;
`;
