// React
import { useEffect, useState, useContext } from "react";

// Import context
import { ScreenDimensionsContext } from "../../../context/ScreenDimensionsContext";

// Import components
import SectionHeader from "../../section-header/SectionHeader";
import OurProjectsItem from "../our-projects-item/OurProjectsItem";
import LinkButton from "../../link-button/LinkButton";

// Import styles
import {
  OurProjectsContainer,
  SliderContainer,
  OurProjectsItemContainer,
  RightButton,
  LeftButton,
} from "./OurProjects.styles";

// Import icons
import { ReactComponent as LeftArrow } from "../../../assets/svg/arrow_left.svg";
import { ReactComponent as RightArrow } from "../../../assets/svg/arrow_right.svg";

// Local projects data
import { ourProjectsData } from "../../../data";

// Calculates the number of project cards that will be shown
function calculateDisplayedCards(screenWidth) {
  if (screenWidth <= 787) return 1;
  else if (screenWidth <= 1056) return 2;
  else return 3;
}

const OurProjects = () => {
  // Screen Width state variable
  const { screenDimensions } = useContext(ScreenDimensionsContext);
  // Τhe number of project cards that will be shown
  const displayedCards = calculateDisplayedCards(screenDimensions.innerWidth);
  // Array of the indexes of cards which will be shown
  const [currentIndexArr, setCurrentIndexArr] = useState([
    ...Array(displayedCards).keys(),
  ]);

  // Touch event  starting touch value
  const [touchStart, setTouchStart] = useState(0);

  // Max cards index based on data length
  const maxIndex = ourProjectsData.length - 1;

  useEffect(() => {
    setCurrentIndexArr([
      ...Array(calculateDisplayedCards(screenDimensions.innerWidth)).keys(),
    ]);
  }, [screenDimensions]);

  const showProjects = () => {
    return (
      <OurProjectsItemContainer>
        {currentIndexArr.map((i) => {
          return (
            <OurProjectsItem
              imagePath={`${process.env.PUBLIC_URL}${ourProjectsData[i].imagePath[0]}`}
              content={ourProjectsData[i].title}
              key={ourProjectsData[i].id}
            />
          );
        })}
      </OurProjectsItemContainer>
    );
  };

  const prevButtonHandler = () => {
    const newIndexArr = currentIndexArr.map((i) => {
      let newIndex = i - displayedCards;
      if (newIndex < 0) {
        return newIndex + maxIndex + 1;
      }
      return newIndex;
    });
    setCurrentIndexArr(newIndexArr);
  };

  const nextButtonHandler = () => {
    const newIndexArr = currentIndexArr.map((i) => {
      let newIndex = i + displayedCards;
      if (newIndex > maxIndex) {
        return newIndex - maxIndex - 1;
      }
      return newIndex;
    });
    setCurrentIndexArr(newIndexArr);
  };

  // Functions for swiping on touch in mobile devices
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    const touchEnd = e.targetTouches[0].clientX;

    if (touchStart - touchEnd > 150) {
      nextButtonHandler();
    } else if (touchStart - touchEnd < -150) {
      prevButtonHandler();
    }
  };

  return (
    <OurProjectsContainer
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <SectionHeader
        title="ΑΠΟΔΕΔΕΙΓΜΕΝΗ ΕΜΠΕΙΡΙΑ"
        size="medium"
      ></SectionHeader>
      <SliderContainer>
        <LeftButton aria-label="Left button" onClick={prevButtonHandler}>
          <LeftArrow />
        </LeftButton>
        {showProjects()}
        <RightButton aria-label="Right button" onClick={nextButtonHandler}>
          <RightArrow />
        </RightButton>
      </SliderContainer>
      <LinkButton to="projects">ΠΡΟΒΟΛΗ ΠΕΡΙΣΣΟΤΕΡΩΝ</LinkButton>
    </OurProjectsContainer>
  );
};

export default OurProjects;
