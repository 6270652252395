// React Router
import { Routes, Route } from "react-router-dom";

// React Helmet package
import { HelmetProvider } from "react-helmet-async";

// Import routes
import Layout from "./routes/layout/Layout";
import Home from "./routes/home/Home";
import Services from "./routes/services/Services";
import Projects from "./routes/projects/Projects";
import AboutUs from "./routes/about-us/AboutUs";
import ContactUs from "./routes/contact-us/ContactUs";
import CookiesPolicy from "./routes/cookies-policy/CookiesPolicy";
import PageNotFound from "./routes/page-not-found/PageNotFound";

function App() {
  return (
    <HelmetProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="services" element={<Services />} />
          <Route path="projects" element={<Projects />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="cookies-policy" element={<CookiesPolicy />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </HelmetProvider>
  );
}

export default App;
