// Import styles
import { PolygonContainer, PolygonInside } from "./Polygon.styles";

const Polygon = ({ children }) => {
  return (
    <PolygonContainer>
      <PolygonInside>{children}</PolygonInside>
    </PolygonContainer>
  );
};

export default Polygon;
