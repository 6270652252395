// Top bar background color
export const topBarBgColor = "#343A40";
export const footerBgColor = "#343A40";
export const backGroundColor = "#f8f9fa";
export const backGroundColorShade = "#f1f3f5";
// export const secondaryColor = "#FFD43B";
export const secondaryColor = "#ff8787";
export const mainColor = "#343A40";
export const mainColorTint = "#adb5bd";

export const messageTopBarColor = "#dee2e6";
export const messageTopBarColorShade = "#ced4da";

// Fornts
export const mainFontColor = "#212529";
export const secondaryFontColor = "#f8f9fa";
export const heroSectionFontColor = "#dee2e6";

export const starColor = "#FFD43B";
export const linkColor = "#5f3dc4";
