// React
import { useState, useEffect, useRef, useCallback } from "react";

// Import styles
import {
  MobileMenuContainer,
  MobileSideBarContainer,
  NavLinksContainer,
  NavLink,
  Divider,
} from "./MobileNavBar.styles";

//Icons
import { ReactComponent as MenuIcon } from "../../../assets/svg/menu.svg";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close_24.svg";
import { ReactComponent as RightArrowIcon } from "../../../assets/svg/arrow_right_24.svg";

const MobileNavBar = ({ navLinksList }) => {
  const [isOpen, setIsOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  const handleOutsideClick = useCallback((event) => {
    if (
      mobileMenuRef.current &&
      !mobileMenuRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("touchend", handleOutsideClick);

    // Specify how to clean up after this effect:
    return () => {
      window.addEventListener("touchend", handleOutsideClick);
      window.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MobileMenuContainer ref={mobileMenuRef}>
      <MenuIcon onClick={toggleMenu} />
      {isOpen && (
        <MobileSideBarContainer>
          <CloseIcon onClick={toggleMenu} />
          {navLinksList.map((link) => (
            <NavLinksContainer key={link.id}>
              <NavLink to={link.route} onClick={toggleMenu}>
                {link.title}
                <RightArrowIcon />
              </NavLink>
              <Divider />
            </NavLinksContainer>
          ))}
        </MobileSideBarContainer>
      )}
    </MobileMenuContainer>
  );
};

export default MobileNavBar;
