import styled from "styled-components";
import {
  mainFontColor,
  backGroundColorShade,
} from "../../utils/styleVariables";

export const ContactUsContainer = styled.div`
  max-width: 136.6rem;
  margin: 0 auto;
`;
export const SectionContainer = styled.main`
  margin: 2.4rem 2.4rem 9.6rem 2.4rem;

  display: flex;

  color: ${mainFontColor};
  h3 {
    font-size: 1.8rem;
    font-weight: 600;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
  }

  @media (max-width: 48em) {
    margin: 2.4rem 4.8rem 9.6rem 4.8rem;

    flex-direction: column-reverse;
    align-items: center;
  }

  @media (max-width: 26em) {
    margin: 2.4rem 2.4rem 9.6rem 2.4rem;
  }
`;

export const WhereToFindUs = styled.div`
  margin-right: 2.4rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  h3,
  h4 {
    margin-bottom: 1.2rem;
  }
  @media (max-width: 48em) {
    margin-top: 3.6rem;
    flex-grow: 0;
  }
`;

export const WhereToFindUsItem = styled.div`
  margin-bottom: 1.2rem;
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
  }

  a,
  span {
    margin-left: 1.2rem;
    font-size: 1.6rem;
    color: ${mainFontColor};
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-grow: 3;
  flex-direction: column;
  h3 {
    margin-bottom: 2.4rem;
  }
  @media (max-width: 48em) {
    flex-grow: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  padding: 3.6rem 2.4rem;

  border: 0.1rem solid ${mainFontColor};
  border-radius: 0.6rem;
  background-color: ${backGroundColorShade};
`;
