import { MainContainer, TopBarContainer, InfoValue } from "./TopBar.styles";

const TopBar = () => {
  return (
    <MainContainer>
      <TopBarContainer>
        <InfoValue>
          ΔΕΥ-ΠΑΡ: <span>08:30-16:00</span>
        </InfoValue>
        <InfoValue>
          ΤΗΛ: <a href="tel:2310788485">2310 788-485</a>
        </InfoValue>
      </TopBarContainer>
    </MainContainer>
  );
};

export default TopBar;
