import styled from "styled-components";

import { backGroundColor, mainFontColor } from "../../utils/styleVariables";

export const PolygonContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 10rem;
  height: 10rem;
  background: ${mainFontColor};
  clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 60%,
    75% 100%,
    25% 100%,
    0% 60%,
    10% 20%
  );

  border: 0.1rem solid ${mainFontColor};
`;

export const PolygonInside = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 9.9rem;
  height: 9.9rem;
  background: ${backGroundColor};
  clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 60%,
    75% 100%,
    25% 100%,
    0% 60%,
    10% 20%
  );
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
