// React
import { useEffect, useState, useContext } from "react";

// Import context
import { ScreenDimensionsContext } from "../../../context/ScreenDimensionsContext";

// Import components
import SectionHeader from "../../section-header/SectionHeader";

// Import styles
import {
  OurCustomersContainer,
  SliderContainer,
  OurCustomersItemContainer,
  LeftButton,
  RigthButton,
} from "./OurCustomers.styles";

// Import icons
import { ReactComponent as LeftArrow } from "../../../assets/svg/arrow_left.svg";
import { ReactComponent as RightArrow } from "../../../assets/svg/arrow_right.svg";

// Customers logos
import { customersLogo } from "../../../data";

// Calculates the number of project cards that will be shown
function calculateDisplayedCards(screenWidth) {
  if (screenWidth <= 787) return 1;
  else if (screenWidth <= 1056) return 2;
  else if (screenWidth <= 1120) return 3;
  else return 4;
}

const OurCustomers = () => {
  // Screen Width state variable
  const { screenDimensions } = useContext(ScreenDimensionsContext);
  // Τhe number of project cards that will be shown
  const displayedCards = calculateDisplayedCards(screenDimensions.innerWidth);
  // Array of the indexes of cards which will be shown
  const [currentIndexArr, setCurrentIndexArr] = useState([
    ...Array(displayedCards).keys(),
  ]);

  // Touch event  starting touch value
  const [touchStart, setTouchStart] = useState(0);

  const maxIndex = customersLogo.length - 1;

  useEffect(() => {
    setCurrentIndexArr([
      ...Array(calculateDisplayedCards(screenDimensions.innerWidth)).keys(),
    ]);
  }, [screenDimensions]);

  const showCustomers = () => {
    return (
      <OurCustomersItemContainer>
        {currentIndexArr.map((i) => {
          return (
            <img
              src={`${process.env.PUBLIC_URL}${customersLogo[i].imagePath}`}
              alt="customer logo"
              key={customersLogo[i].id}
            />
          );
        })}
      </OurCustomersItemContainer>
    );
  };
  const prevButtonHandler = () => {
    const newIndexArr = currentIndexArr.map((i) => {
      let newIndex = i - displayedCards;
      if (newIndex < 0) {
        return newIndex + maxIndex + 1;
      }
      return newIndex;
    });
    setCurrentIndexArr(newIndexArr);
  };

  const nextButtonHandler = () => {
    const newIndexArr = currentIndexArr.map((i) => {
      let newIndex = i + displayedCards;
      if (newIndex > maxIndex) {
        return newIndex - maxIndex - 1;
      }
      return newIndex;
    });
    setCurrentIndexArr(newIndexArr);
  };

  // Functions for swiping on touch in mobile devices
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    const touchEnd = e.targetTouches[0].clientX;

    if (touchStart - touchEnd > 150) {
      nextButtonHandler();
    } else if (touchStart - touchEnd < -150) {
      prevButtonHandler();
    }
  };

  return (
    <OurCustomersContainer
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <SectionHeader
        title="ΠΕΛΑΤΕΣ ΠΟΥ ΜΑΣ ΕΜΠΙΣΤΕΥΤΗΚΑΝ"
        size="medium"
      ></SectionHeader>
      <SliderContainer>
        <LeftButton aria-label="Left button" onClick={prevButtonHandler}>
          <LeftArrow />
        </LeftButton>
        {showCustomers()}
        <RigthButton aria-label="Right button" onClick={nextButtonHandler}>
          <RightArrow />
        </RigthButton>
      </SliderContainer>
    </OurCustomersContainer>
  );
};

export default OurCustomers;
