import styled from "styled-components";

export const MapContainer = styled.div``;

export const MapFrame = styled.iframe`
  border: 0;
  width: 40rem;
  height: 30rem;

  @media (max-width: 30em) {
    width: 32rem;
    height: 26rem;
  }

  @media (max-width: 23em) {
    width: 28rem;
    height: 22rem;
  }
`;
