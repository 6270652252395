// Import styles
import { SmallHeader, MediumHeader, LargeHeader } from "./SectionHeader.styles";

const getHeadersize = (size = "medium") =>
  ({
    small: SmallHeader,
    medium: MediumHeader,
    large: LargeHeader,
  }[size]);

const SectionHeader = ({ children, size, title, ...otherProps }) => {
  const CustomHeader = getHeadersize(size);
  return (
    <CustomHeader {...otherProps}>
      <h2>{title}</h2>
      <div></div>
      {children}
    </CustomHeader>
  );
};

export default SectionHeader;
