import styled from "styled-components";

// - Spacing system (px)
// 2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

// - Font sizes (px)
// 10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

export const Container = styled.nav`
  margin: 0 auto;
  max-width: 136.6rem;
  display: flex;
  flex-wrap: wrap;
  padding: 1.2rem 2.4rem;

  img {
    height: 9.8rem;
    margin: 0 2.4rem;
  }

  @media (max-width: 56em) {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    img {
      height: 8rem;
    }
  }
`;

export const NavLinksContainer = styled.nav`
  align-self: end;
  display: flex;
  margin: 1.2rem auto 1.2rem 4.4rem;

  @media (max-width: 56em) {
    align-self: center;
    margin: 1.2rem 0 0 0;
  }
  @media (max-width: 33em) {
    display: none;
  }
`;
