import styled from "styled-components";

export const ServicesContainer = styled.div`
  max-width: 136.6rem;
  margin: 0 auto;
`;
export const SectionContainer = styled.main`
  display: flex;
  justify-content: space-evenly;
  margin: 2.4rem 2.4rem 9.6rem 2.4rem;
`;

export const ServicesListContainer = styled.section`
  display: flex;
  flex-direction: column;

  margin-right: 3.6rem;
  @media (max-width: 48em) {
    margin-right: 2.4rem;
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  img {
    width: 30rem;
    height: 20rem;
    margin-bottom: 3.6rem;
  }

  @media (max-width: 48em) {
    display: none;
  }
`;
