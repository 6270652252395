import styled from "styled-components";
import { Link } from "react-router-dom";
import { mainFontColor } from "../../../utils/styleVariables";

export const LinkItem = styled(Link)`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.4rem 1.4rem 1.4rem;
  text-decoration: none;
  cursor: pointer;
  color: ${mainFontColor};
  font-size: 1.6rem;
  font-weight: 400;

  border: 0.1rem solid transparent;
  svg {
    display: block;
    position: absolute;
    top: 2rem;
    visibility: visible;
  }

  :hover {
    //Prevents right shifting
    text-shadow: 0 0 0.1rem ${mainFontColor};
  }

  @media (max-width: 56em) {
    margin-left: 1.4rem;
    align-self: center;
    padding: 0.8rem;
  }
`;
