import { useState } from "react";

// Helmet package
import { Helmet } from "react-helmet-async";

// Import Firestore DB
import { saveMessageToDb } from "../../utils/firebase";

//Import components
import Modal from "../../components/modal/Modal";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import SectionHeader from "../../components/section-header/SectionHeader";
import FormInput from "../../components/form-input/FormInput";
import Button from "../../components/button/Button";

import { validateEmail } from "../../utils/utils";

// Import stryles
import {
  ContactUsContainer,
  SectionContainer,
  WhereToFindUs,
  WhereToFindUsItem,
  FormContainer,
  Form,
} from "./ContactUs.styles";

// Icons
import { ReactComponent as MailIcon } from "../../assets/svg/mail.svg";
import { ReactComponent as PhoneIcon } from "../../assets/svg/phone.svg";
import { ReactComponent as MobilePhoneIcon } from "../../assets/svg/smartphone_20.svg";
import { ReactComponent as TimeIcon } from "../../assets/svg/time.svg";

const breadcrumbLinks = [
  {
    id: 1,
    title: "Αρχική",
    route: "/",
  },
  {
    id: 2,
    title: "Επικοινωνία",
    route: "/contact-us",
  },
];

const defaultFormFields = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
};

const ContactUs = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [errorMessages, setErrorMessages] = useState(defaultFormFields);

  const { name, email, phone, subject, message } = formFields;

  const handleSubmit = async (event) => {
    event.preventDefault();
    let hasError = false;
    const newErrorMessages = {};

    if (name.length === 0) {
      hasError = true;
      newErrorMessages.name = "To πεδίο είναι υποχρεωτικό";
    }

    if (email.length === 0) {
      hasError = true;
      newErrorMessages.email = "To πεδίο είναι υποχρεωτικό";
    } else if (!validateEmail(email)) {
      hasError = true;
      newErrorMessages.email = "Εισάγεται ένα έγκυρο Email";
    }

    if (message.length === 0) {
      hasError = true;
      newErrorMessages.message = "To πεδίο είναι υποχρεωτικό";
    } else if (message.length < 3) {
      hasError = true;
      newErrorMessages.message = "Παρακαλώ εισάγεται το μήνυμα σας";
    }

    if (hasError) {
      setErrorMessages(newErrorMessages);
    } else {
      const msg = { name, email, phone, subject, message };
      saveMessageToDb(msg)
        .then(() => {
          setShowModal(true);
          setModalMessage({
            title: "Επιτυχής Αποστολή",
            message: "Το μήνυμα σας στάλθηκε επιτυχώς.",
          });
        })
        .catch((error) => {
          setShowModal(true);
          setModalMessage({
            title: "Παρουσιάστηκε Σφάλμα",
            message: "Παρουσιάστηκε σφάλμα, παρακαλώ δοκιμάστε ξανά αργότερα.",
          });
        });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setErrorMessages({ ...errorMessages, name: "" });
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <ContactUsContainer>
      <Helmet>
        <title>Gas Team Κατασκευαστική | Επικοινωνία</title>
      </Helmet>
      {showModal && (
        <Modal title={modalMessage.title} message={modalMessage.message} />
      )}
      <Breadcrumb links={breadcrumbLinks} />
      <SectionHeader title="ΕΠΙΚΟΙΝΩΝΙΑ" size="large" />
      <SectionContainer>
        <WhereToFindUs>
          <h3>Θα μας βρείτε</h3>
          <h4>Νεοχωρούδα, Θεσσαλονίκη, 54500</h4>
          <WhereToFindUsItem>
            <PhoneIcon />
            <a href="tel:2310788485">2310 788-485</a>
          </WhereToFindUsItem>
          <WhereToFindUsItem>
            <MobilePhoneIcon />
            <a href="tel:6948500518">694 8500518</a>
          </WhereToFindUsItem>
          <WhereToFindUsItem>
            <TimeIcon />
            <span>Δευτέρα - Παρασκευή 08:30-16:00</span>
          </WhereToFindUsItem>
          <WhereToFindUsItem>
            <MailIcon /> <span>gasteam@otenet.gr</span>
          </WhereToFindUsItem>
        </WhereToFindUs>
        <FormContainer>
          <h3>Συμπληρώστε τη φόρμα</h3>
          <Form onSubmit={handleSubmit}>
            <FormInput
              label="Το όνομα σας"
              type="text"
              placeholder="Όνομα"
              required
              onChange={handleChange}
              name="name"
              value={name}
              errMsg={errorMessages.name}
            />
            <FormInput
              label="Το email σας"
              type="text"
              placeholder="Email"
              required
              onChange={handleChange}
              name="email"
              value={email}
              errMsg={errorMessages.email}
            />
            <FormInput
              label="Τηλέφωνο επικοινωνίας (προαιρετικό)"
              type="phone"
              placeholder="Τηλέφωνο"
              onChange={handleChange}
              name="phone"
              value={phone}
            />
            <FormInput
              label="Θέμα (προαιρετικό)"
              placeholder="Θέμα"
              type="text"
              onChange={handleChange}
              name="subject"
              value={subject}
            />
            <FormInput
              label="Το μήνυμά σας"
              inputType="textarea"
              rows="8"
              placeholder="Το μήνυμά σας..."
              required
              onChange={handleChange}
              name="message"
              value={message}
              errMsg={errorMessages.message}
            />
            <Button onClick={handleSubmit}>Αποστολή</Button>
          </Form>
        </FormContainer>
      </SectionContainer>
    </ContactUsContainer>
  );
};

export default ContactUs;
