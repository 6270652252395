// Import components
import SectionHeader from "../../section-header/SectionHeader";

// Import styles
import { OurProjectsContainer } from "./OurProjectsItem.styles";

const OurProjectsItem = ({ content, imagePath }) => {
  return (
    <OurProjectsContainer>
      <img src={imagePath} alt={`${content} φωτογραφία έργου`} />
      <SectionHeader size="small">
        <p>{content}</p>
      </SectionHeader>
    </OurProjectsContainer>
  );
};

export default OurProjectsItem;
