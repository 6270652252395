import { initializeApp } from "firebase/app";

import { getAnalytics, setConsent } from "firebase/analytics";

import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDQyM0M2yoEzjeVwBNOoMEg6WJn62hCBPs",
  authDomain: "gasteam-con.firebaseapp.com",
  projectId: "gasteam-con",
  storageBucket: "gasteam-con.appspot.com",
  messagingSenderId: "503467700481",
  appId: "1:503467700481:web:84f08ad2aafa104d13eb00",
  measurementId: "G-P5GT8Q108L",
};

const firebaseOptions = {
  automaticDataCollectionEnabled: false,
};
const app = initializeApp(firebaseConfig, firebaseOptions);

setConsent({
  analytics_storage: "denied",
  ad_storage: "denied",
  // Upgrade to consent mode v2
  ad_user_data: "denied",
  ad_personalization: "denied",
});

const analytics = getAnalytics(app);

export const db = getFirestore(app);

// Enable GA cookies
export const allowCookies = () => {
  setConsent({
    analytics_storage: "granted",
  });
};

export const saveMessageToDb = async (message) => {
  const createdAt = new Date();

  const docData = {
    sender: {
      email: message.email,
      name: message.name,
      phone: message.phone,
    },
    subject: message.subject,
    message: message.message,
    createdAt,
  };

  try {
    await addDoc(collection(db, "messages"), docData);
  } catch (error) {
    throw error;
  }
};
