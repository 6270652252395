import styled from "styled-components";
import { mainFontColor } from "../../../utils/styleVariables";

export const OurProjectsContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.2rem 0 4.4rem 0;
`;

export const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const OurProjectsItemContainer = styled.div`
  max-width: 117.4rem;
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin: 2.4rem 0 3.6rem 0;

  @media (max-width: 66em) {
    justify-content: space-evenly;
  }
`;

const Button = styled.button`
  // Remove button default style
  padding: 0;
  border: none;
  background: none;
  svg {
    cursor: pointer;
    fill: ${mainFontColor};
    &:active {
      transform: scale(1.2);
    }
  }
`;

export const LeftButton = styled(Button)`
  margin: 0 2.4rem 0 2.4rem;

  @media (max-width: 66em) {
    margin-left: 6.2rem;
  }

  @media (max-width: 30em) {
    margin: 0 0.8rem 0 2.4rem;
  }
  @media (max-width: 23em) {
    margin: 0;
  }
`;

export const RightButton = styled(Button)`
  margin: 0 2.4rem 0 2.4rem;

  @media (max-width: 66em) {
    margin-right: 6.2rem;
  }

  @media (max-width: 30em) {
    margin: 0 2.4rem 0 0.8rem;
  }

  @media (max-width: 23em) {
    margin: 0;
  }
`;
