import styled from "styled-components";
import { secondaryColor, mainColor } from "../../../utils/styleVariables";

export const ServiceItemContainer = styled.div`
  margin: 1.8rem 0;
  align-items: start;
  max-width: 64rem;

  display: grid;
  grid-template-columns: 3.6rem auto;
  grid-template-rows: 3.6rem auto;

  justify-items: start;
  align-items: center;

  column-gap: 2.4rem;
  row-gap: 1.2rem;

  @media (max-width: 48em) {
    margin: 1.2rem 0;
    grid-template-columns: 2.4rem auto;
    grid-template-rows: auto auto;
    align-items: start;
    row-gap: 1.2rem;
  }
`;

export const ServiceTitle = styled.h2`
  grid-area: 1 / 2 / 2 / 3;
  font-size: 1.8rem;
  font-weight: 500;

  @media (max-width: 66em) {
    font-size: 1.6rem;
  }

  @media (max-width: 24em) {
    font-size: 1.4rem;
  }
`;

export const ServiceContent = styled.p`
  grid-area: 2 / 2 / 3 / 3;
  font-size: 1.6rem;
  line-height: 1.2;
  text-align: left;

  @media (max-width: 24em) {
    font-size: 1.4rem;
  }
`;

export const ServiceContentList = styled.ul`
  grid-area: 2 / 2 / 3 / 3;
  list-style-type: none;
  display: flex;
  flex-direction: column;
`;

export const ServiceContentListItem = styled.li`
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 1.2;
  display: flex;
  align-items: center;
  text-align: left;

  @media (max-width: 24em) {
    font-size: 1.4rem;
  }
`;

export const Bullet = styled.span`
  margin-right: 1.2rem;
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  padding: 0.3rem;
  background-color: ${secondaryColor};
`;

export const ServiceButton = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${secondaryColor};
  cursor: pointer;
  border: 0.1rem solid ${mainColor};

  svg {
    fill: ${mainColor};
  }

  @media (max-width: 48em) {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const ActiveServiceButton = styled(ServiceButton)`
  border-color: ${secondaryColor};
  background-color: transparent;

  svg {
    fill: ${secondaryColor};
  }
`;
