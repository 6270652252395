// Import styles
import {
  FormInputContainer,
  ErrorMessage,
  Input,
  TextArea,
} from "./FormInput.styles";

const FormInput = ({
  inputType,
  label,
  placeholder,
  errMsg,
  ...otherProps
}) => {
  const getInput = (inputType = "input") =>
    ({
      input: Input,
      textarea: TextArea,
    }[inputType]);

  const CustomInput = getInput(inputType);
  return (
    <FormInputContainer>
      <label htmlFor={otherProps.name}>{label}</label>
      <CustomInput {...otherProps} placeholder={placeholder} />
      {errMsg && <ErrorMessage>{errMsg}</ErrorMessage>}
    </FormInputContainer>
  );
};

export default FormInput;
