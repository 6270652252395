// Import styles
import { BaseButton, TransparentButton } from "./Button.styles";

const getButton = (buttonType = "base") =>
  ({
    base: BaseButton,
    transparent: TransparentButton,
  }[buttonType]);

const Button = ({ children, buttonType, onClick, ...otherProps }) => {
  const CustomButton = getButton(buttonType);
  return <CustomButton onClick={onClick}>{children}</CustomButton>;
};

export default Button;
