//Import Components
import SectionHeader from "../../section-header/SectionHeader";
import Polygon from "../../polygon/Polygon";

// Import styles
import { WhyUsItemContainer } from "./WhyUsItem.styles";

const WhyUsItem = ({ children, title, content }) => {
  return (
    <WhyUsItemContainer>
      <Polygon>{children}</Polygon>
      <SectionHeader size="small" title={title}>
        <p>{content}</p>
      </SectionHeader>
    </WhyUsItemContainer>
  );
};

export default WhyUsItem;
