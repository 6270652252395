// React
import { useEffect, useState } from "react";

import Cookies from "universal-cookie";

import { Link } from "react-router-dom";

// Import Components
import Button from "../button/Button";

// Firebase utils
import { allowCookies } from "../../utils/firebase";

// Import styles
import {
  CookiesConsentContainer,
  ButtonsContainer,
} from "./CookiesConsent.styles";
const cookies = new Cookies();

const CookiesConsent = () => {
  const [displayConsentBox, setDisplayConsentBox] = useState(true);
  const [removeConsentBox, setRemoveConsentBox] = useState(false);

  useEffect(() => {
    const cookieConsent = cookies.get("cookies-consent");
    if (!cookieConsent || cookieConsent.length === 0) {
      setDisplayConsentBox(true);
    } else {
      setDisplayConsentBox(false);
    }
  }, []);

  const handleUserConnsent = (granted = false) => {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);

    if (granted) {
      cookies.set("cookies-consent", "granted", {
        path: "/",
        expires: date,
      });
      allowCookies();
    } else {
      cookies.set("cookies-consent", "denied", {
        path: "/",
        expires: date,
      });
    }
    setRemoveConsentBox(true);
  };

  return (
    displayConsentBox && (
      <CookiesConsentContainer
        displayBox={displayConsentBox}
        removeBox={removeConsentBox}
      >
        <h2>Στον ιστοτοπό μας χρησιμοποιούμε cookies</h2>
        <p>
          Κάνοντας κλικ στο κουμπί "Αποδοχή Όλων", συναινείς στη χρήση cookies
          για σκοπούς στατιστικής. Αν κάνεις κλικ στην επιλογή "Απόρριψη",
          συναινείς μόνο για τη χρήση των αναγκαίων & λειτουργικών cookies.
          <Link to="cookies-policy">Μάθετε περισσότερα</Link>
        </p>
        <ButtonsContainer>
          <Button
            buttonType={"transparent"}
            onClick={() => handleUserConnsent(true)}
          >
            Αποδοχή Όλων
          </Button>
          <Button
            buttonType={"transparent"}
            onClick={() => handleUserConnsent()}
          >
            Απόρριψη
          </Button>
        </ButtonsContainer>
      </CookiesConsentContainer>
    )
  );
};

export default CookiesConsent;
