// Import components
import SectionHeader from "../../section-header/SectionHeader";
import OurServicesItem from "../our-services-item/OurServicesItem";
import LinkButton from "../../link-button/LinkButton";

// Import icons
import { ReactComponent as EnergySavingIcon } from "../../../assets/svg/energy_savings.svg";
import { ReactComponent as CheckListIcon } from "../../../assets/svg/fact_check.svg";
import { ReactComponent as InstalationsIcon } from "../../../assets/svg/build.svg";

// Import styles
import {
  OurServicesContainer,
  OurServicesItemContainer,
} from "./OurServices.styles";

const OurServices = () => {
  return (
    <OurServicesContainer>
      <SectionHeader
        title="ΜΕΓΑΛΟ ΕΥΡΟΣ ΥΠΗΡΕΣΙΩΝ"
        size="medium"
      ></SectionHeader>
      <OurServicesItemContainer>
        <OurServicesItem
          imagePath={`${process.env.PUBLIC_URL}/images/our-services-section/money-saving.webp`}
          title="ΕΞΟΙΚΟΝΟΜΗΣΗ ΕΝΕΡΓΕΙΑΣ"
          content="Μειώστε τις δαπάνες σας για ενεργεία μέσω των έργων εξοικονόμησης ενέργειας που προσφέρουμε"
        >
          <EnergySavingIcon />
        </OurServicesItem>
        <OurServicesItem
          imagePath={`${process.env.PUBLIC_URL}/images/our-services-section/instalation.webp`}
          title="ΟΛΟΚΛΗΡΩΜΕΝΕΣ ΕΓΚΑΤΑΣΤΑΣΕΙΣ"
          content="Ολοκληρωμένες εγκαταστάσεις τροφοδότησης υγραερίου και φυσικού αερίου και πλήθος άλλων έργων"
        >
          <InstalationsIcon />
        </OurServicesItem>
        <OurServicesItem
          imagePath={`${process.env.PUBLIC_URL}/images/our-services-section/certification.webp`}
          title="ΠΙΣΤΟΠΟΙΗΣΕΙΣ"
          content="Πιστοποιήσεις και έλεγχοι εγκαταστάσεων - δεξαμενών υγραερίου κλπ"
        >
          <CheckListIcon />
        </OurServicesItem>
      </OurServicesItemContainer>
      <LinkButton to="services">ΟΛΕΣ ΟΙ ΥΠΗΡΕΣΙΕΣ ΜΑΣ</LinkButton>
    </OurServicesContainer>
  );
};

export default OurServices;
