// Import styles
import {
  BaseLinkButton,
  SmallBaseLinkButton,
  TransparentLinkButton,
} from "./LinkButton.styles";

const getButton = (buttonType = "base") =>
  ({
    base: BaseLinkButton,
    smallBase: SmallBaseLinkButton,
    transparent: TransparentLinkButton,
  }[buttonType]);

const LinkButton = ({ to, children, buttonType, ...otherProps }) => {
  const CustomButton = getButton(buttonType);
  return <CustomButton to={to}>{children}</CustomButton>;
};

export default LinkButton;
