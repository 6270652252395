// React
import { Fragment } from "react";
// React router
import { Outlet } from "react-router-dom";

// Components
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import CookiesConsentContainer from "../../components/cookies-consent/CookiesConsent";

import ScrollToTop from "../../utils/ScrollToTop";

const Layout = () => {
  return (
    <Fragment>
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />
      <CookiesConsentContainer />
    </Fragment>
  );
};

export default Layout;
