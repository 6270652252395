export const ourProjectsData = [
  {
    id: 1,
    imagePath: [
      "/images/our-projects/diktya-pyrosbeshs-dp1.webp",
      "/images/our-projects/diktya-pyrosbeshs-5.webp",
      "/images/our-projects/diktya-pyrosbeshs-19.webp",
      "/images/our-projects/diktya-pyrosbeshs-27.webp",
      "/images/our-projects/diktya-pyrosbeshs-34.webp",
      "/images/our-projects/diktya-pyrosbeshs-52.webp",
      "/images/our-projects/diktya-pyrosbeshs-53.webp",
      "/images/our-projects/diktya-pyrosbeshs-54.webp",
      "/images/our-projects/diktya-pyrosbeshs-55.webp",
      "/images/our-projects/diktya-pyrosbeshs-88.webp",
      "/images/our-projects/diktya-pyrosbeshs-90.webp",
    ],
    title: "Δίκτυα Πυρόσβεσης",
  },
  {
    id: 2,
    imagePath: [
      "/images/our-projects/diktya-trofimwn-dt1.webp",
      "/images/our-projects/diktya-trofimwn-25.webp",
      "/images/our-projects/diktya-trofimwn-28.webp",
      "/images/our-projects/diktya-trofimwn-29.webp",
      "/images/our-projects/diktya-trofimwn-31.webp",
      "/images/our-projects/diktya-trofimwn-32.webp",
      "/images/our-projects/diktya-trofimwn-33.webp",
      "/images/our-projects/diktya-trofimwn-39.webp",
      "/images/our-projects/diktya-trofimwn-42.webp",
      "/images/our-projects/diktya-trofimwn-69.webp",
      "/images/our-projects/diktya-trofimwn-70.webp",
      "/images/our-projects/diktya-trofimwn-73.webp",
      "/images/our-projects/diktya-trofimwn-74.webp",
      "/images/our-projects/diktya-trofimwn-93.webp",
      "/images/our-projects/diktya-trofimwn-100.webp",
      "/images/our-projects/diktya-trofimwn-110.webp",
      "/images/our-projects/diktya-trofimwn-111.webp",
      "/images/our-projects/diktya-trofimwn-113.webp",
      "/images/our-projects/diktya-trofimwn-114.webp",
      "/images/our-projects/diktya-trofimwn-126.webp",
      "/images/our-projects/diktya-trofimwn-129.webp",
      "/images/our-projects/diktya-trofimwn-131.webp",
      "/images/our-projects/diktya-trofimwn-132.webp",
    ],
    title: "Δίκτυα Τροφίμων",
  },
  {
    id: 3,
    imagePath: [
      "/images/our-projects/egkatastaseis-bioaerioy-eb1.webp",
      "/images/our-projects/egkatastaseis-bioaerioy-8.webp",
      "/images/our-projects/egkatastaseis-bioaerioy-9.webp",
      "/images/our-projects/egkatastaseis-bioaerioy-10.webp",
      "/images/our-projects/egkatastaseis-bioaerioy-11.webp",
      "/images/our-projects/egkatastaseis-bioaerioy-12.webp",
      "/images/our-projects/egkatastaseis-bioaerioy-13.webp",
      "/images/our-projects/egkatastaseis-bioaerioy-14.webp",
      "/images/our-projects/egkatastaseis-bioaerioy-15.webp",
      "/images/our-projects/egkatastaseis-bioaerioy-16.webp",
      "/images/our-projects/egkatastaseis-bioaerioy-17.webp",
      "/images/our-projects/egkatastaseis-bioaerioy-56.webp",
      "/images/our-projects/egkatastaseis-bioaerioy-57.webp",
      "/images/our-projects/egkatastaseis-bioaerioy-58.webp",
    ],
    title: "Εγκατάσταση Βιοαερίου",
  },
  {
    id: 4,
    imagePath: [
      "/images/our-projects/egkatastaseis-bionthzel-ebd1.webp",
      "/images/our-projects/egkatastaseis-bionthzel-189.webp",
      "/images/our-projects/egkatastaseis-bionthzel-190.webp",
    ],
    title: "Εγκατάσταση Βιοντίζελ",
  },
  {
    id: 5,
    imagePath: [
      "/images/our-projects/egkatastaseis-ygraerioy-ey1.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-22.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-35.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-36.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-37.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-38.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-43.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-48.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-51.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-84.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-105.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-106.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-107.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-108.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-115.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-117.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-120.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-133.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-134.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-136.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-137.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-142.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-147.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-151.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-152.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-179.webp",
      "/images/our-projects/egkatastaseis-ygraerioy-181.webp",
    ],
    title: "Εγκατάσταση Υγραερίου",
  },
  {
    id: 6,
    imagePath: [
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-efa1.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-4.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-21.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-26.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-46.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-59.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-63.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-72.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-75.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-76.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-77.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-78.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-79.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-87.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-112.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-121.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-122.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-127.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-130.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-143.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-144.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-153.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-154.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-155.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-183.webp",
      "/images/our-projects/egkatastaseis-fysikoy-aerioy-188.webp",
    ],
    title: "Εγκατάσταση Φυσικού Αερίου",
  },
  {
    id: 7,
    imagePath: [
      "/images/our-projects/eidikes-kataskeyes-ek1.webp",
      "/images/our-projects/eidikes-kataskeyes-178.webp",
      "/images/our-projects/eidikes-kataskeyes-3.webp",
      "/images/our-projects/eidikes-kataskeyes-18.webp",
      "/images/our-projects/eidikes-kataskeyes-47.webp",
      "/images/our-projects/eidikes-kataskeyes-71.webp",
      "/images/our-projects/eidikes-kataskeyes-83.webp",
      "/images/our-projects/eidikes-kataskeyes-89.webp",
      "/images/our-projects/eidikes-kataskeyes-99.webp",
      "/images/our-projects/eidikes-kataskeyes-101.webp",
      "/images/our-projects/eidikes-kataskeyes-102.webp",
      "/images/our-projects/eidikes-kataskeyes-109.webp",
      "/images/our-projects/eidikes-kataskeyes-124.webp",
      "/images/our-projects/eidikes-kataskeyes-128.webp",
      "/images/our-projects/eidikes-kataskeyes-135.webp",
      "/images/our-projects/eidikes-kataskeyes-141.webp",
      "/images/our-projects/eidikes-kataskeyes-145.webp",
      "/images/our-projects/eidikes-kataskeyes-146.webp",
      "/images/our-projects/eidikes-kataskeyes-148.webp",
      "/images/our-projects/eidikes-kataskeyes-173.webp",
    ],
    title: "Ειδικές Κατασκεύες",
  },
  {
    id: 8,
    imagePath: [
      "/images/our-projects/kyklwmata-atmoy-ka1.webp",
      "/images/our-projects/kyklwmata-atmoy-1.webp",
      "/images/our-projects/kyklwmata-atmoy-7.webp",
      "/images/our-projects/kyklwmata-atmoy-20.webp",
      "/images/our-projects/kyklwmata-atmoy-40.webp",
      "/images/our-projects/kyklwmata-atmoy-45.webp",
      "/images/our-projects/kyklwmata-atmoy-60.webp",
      "/images/our-projects/kyklwmata-atmoy-64.webp",
      "/images/our-projects/kyklwmata-atmoy-65.webp",
      "/images/our-projects/kyklwmata-atmoy-67.webp",
      "/images/our-projects/kyklwmata-atmoy-82.webp",
      "/images/our-projects/kyklwmata-atmoy-85.webp",
      "/images/our-projects/kyklwmata-atmoy-86.webp",
      "/images/our-projects/kyklwmata-atmoy-92.webp",
      "/images/our-projects/kyklwmata-atmoy-119.webp",
      "/images/our-projects/kyklwmata-atmoy-150.webp",
      "/images/our-projects/kyklwmata-atmoy-157.webp",
      "/images/our-projects/kyklwmata-atmoy-186.webp",
      "/images/our-projects/kyklwmata-atmoy-187.webp",
    ],
    title: "Κυκλώματα Ατμού",
  },
  {
    id: 9,
    imagePath: [
      "images/our-projects/kyklwmata-8ermanshs-k81.webp",
      "images/our-projects/kyklwmata-8ermanshs-24.webp",
      "images/our-projects/kyklwmata-8ermanshs-25.webp",
      "images/our-projects/kyklwmata-8ermanshs-49.webp",
      "images/our-projects/kyklwmata-8ermanshs-80.webp",
      "images/our-projects/kyklwmata-8ermanshs-81.webp",
      "images/our-projects/kyklwmata-8ermanshs-98.webp",
      "images/our-projects/kyklwmata-8ermanshs-103.webp",
      "images/our-projects/kyklwmata-8ermanshs-104.webp",
      "images/our-projects/kyklwmata-8ermanshs-116.webp",
      "images/our-projects/kyklwmata-8ermanshs-118.webp",
      "images/our-projects/kyklwmata-8ermanshs-123.webp",
      "images/our-projects/kyklwmata-8ermanshs-139.webp",
      "images/our-projects/kyklwmata-8ermanshs-140.webp",
      "images/our-projects/kyklwmata-8ermanshs-149.webp",
      "images/our-projects/kyklwmata-8ermanshs-160.webp",
      "images/our-projects/kyklwmata-8ermanshs-161.webp",
      "images/our-projects/kyklwmata-8ermanshs-162.webp",
      "images/our-projects/kyklwmata-8ermanshs-182.webp",
    ],
    title: "Κυκλώματα Θέρμανσης",
  },
  {
    id: 10,
    imagePath: [
      "images/our-projects/kyklwmata-psy3hs-kp1.webp",
      "images/our-projects/kyklwmata-psy3hs-23.webp",
      "images/our-projects/kyklwmata-psy3hs-30.webp",
      "images/our-projects/kyklwmata-psy3hs-41.webp",
      "images/our-projects/kyklwmata-psy3hs-94.webp",
      "images/our-projects/kyklwmata-psy3hs-95.webp",
      "images/our-projects/kyklwmata-psy3hs-96.webp",
      "images/our-projects/kyklwmata-psy3hs-97.webp",
      "images/our-projects/kyklwmata-psy3hs-156.webp",
      "images/our-projects/kyklwmata-psy3hs-163.webp",
      "images/our-projects/kyklwmata-psy3hs-164.webp",
      "images/our-projects/kyklwmata-psy3hs-172.webp",
      "images/our-projects/kyklwmata-psy3hs-174.webp",
      "images/our-projects/kyklwmata-psy3hs-175.webp",
      "images/our-projects/kyklwmata-psy3hs-176.webp",
      "images/our-projects/kyklwmata-psy3hs-177.webp",
    ],
    title: "Κυκλώματα Ψύξης",
  },
  {
    id: 11,
    imagePath: [
      "images/our-projects/systhmata-epe3ergasias-neroy-sen1.webp",
      "images/our-projects/systhmata-epe3ergasias-neroy-61.webp",
      "images/our-projects/systhmata-epe3ergasias-neroy-62.webp",
      "images/our-projects/systhmata-epe3ergasias-neroy-66.webp",
    ],
    title: "Σύστημα Επεξεργασίας Νερού",
  },
  {
    id: 12,
    imagePath: [
      "/images/our-projects/systhmata-symparagwghs-ss1.webp",
      "/images/our-projects/systhmata-symparagwghs-167.webp",
      "/images/our-projects/systhmata-symparagwghs-168.webp",
      "/images/our-projects/systhmata-symparagwghs-169.webp",
      "/images/our-projects/systhmata-symparagwghs-170.webp",
      "/images/our-projects/systhmata-symparagwghs-171.webp",
    ],
    title: "Σύστημα Επεξεργασίας Συμπαραγωγής",
  },
];

export const customersLogo = [
  {
    id: 1,
    imagePath: "/images/customers-logo/agakou-8.png",
  },
  {
    id: 2,
    imagePath: "/images/customers-logo/anthemus-43.png",
  },
  {
    id: 3,
    imagePath: "/images/customers-logo/apg-1.png",
  },
  {
    id: 4,
    imagePath: "/images/customers-logo/argos-10.png",
  },
  {
    id: 5,
    imagePath: "/images/customers-logo/axionte-54.png",
  },

  {
    id: 6,
    imagePath: "/images/customers-logo/coral-gas-2.png",
  },
  {
    id: 7,
    imagePath: "/images/customers-logo/ds-3.png",
  },
  {
    id: 8,
    imagePath: "/images/customers-logo/ecc-4.png",
  },
  {
    id: 9,
    imagePath: "/images/customers-logo/estia-16.png",
  },
  {
    id: 10,
    imagePath: "/images/customers-logo/eteka-52.png",
  },
  {
    id: 11,
    imagePath: "/images/customers-logo/evrika-38.png",
  },
  {
    id: 12,
    imagePath: "/images/customers-logo/expotan-35.png",
  },
  {
    id: 13,
    imagePath: "/images/customers-logo/fmi-51.png",
  },
  {
    id: 14,
    imagePath: "/images/customers-logo/giantsis-48.png",
  },
  {
    id: 15,
    imagePath: "/images/customers-logo/gristiren-34.png",
  },
  {
    id: 16,
    imagePath: "/images/customers-logo/jiannis-14.png",
  },
  {
    id: 17,
    imagePath: "/images/customers-logo/kes-39.png",
  },
  {
    id: 18,
    imagePath: "/images/customers-logo/korfi-12.png",
  },
  {
    id: 19,
    imagePath: "/images/customers-logo/lantavos-40.png",
  },
  {
    id: 20,
    imagePath: "/images/customers-logo/manos-36.png",
  },
  {
    id: 21,
    imagePath: "/images/customers-logo/matis-9.png",
  },
  {
    id: 22,
    imagePath: "/images/customers-logo/mesogeios-20.png",
  },
  {
    id: 23,
    imagePath: "/images/customers-logo/multy-foam-5.png",
  },
  {
    id: 24,
    imagePath: "/images/customers-logo/oktanio-50.png",
  },
  {
    id: 25,
    imagePath: "/images/customers-logo/papadopoulos-22.png",
  },
  {
    id: 26,
    imagePath: "/images/customers-logo/pelito-26.png",
  },
  {
    id: 27,
    imagePath: "/images/customers-logo/psaroudakis-45.png",
  },
  {
    id: 28,
    imagePath: "/images/customers-logo/Solergon-Logo-27.png",
  },
  {
    id: 29,
    imagePath: "/images/customers-logo/styropan-18.png",
  },
  {
    id: 30,
    imagePath: "/images/customers-logo/thermoplastiki-17.png",
  },
  {
    id: 31,
    imagePath: "/images/customers-logo/triton-28.png",
  },
  {
    id: 32,
    imagePath: "/images/customers-logo/tsaroucha-29.png",
  },
  {
    id: 33,
    imagePath: "/images/customers-logo/vamvalis-11.png",
  },
  {
    id: 34,
    imagePath: "/images/customers-logo/vioser-30.png",
  },
  {
    id: 35,
    imagePath: "/images/customers-logo/vitom-13.png",
  },
  {
    id: 36,
    imagePath: "/images/customers-logo/vora-31.png",
  },
  {
    id: 37,
    imagePath: "/images/customers-logo/wonderplant-7.png",
  },
];

// Main navigation links
export const navLinksList = [
  {
    id: 1,
    title: "ΑΡΧΙΚΗ",
    route: "/",
  },
  {
    id: 2,
    title: "ΥΠΗΡΕΣΙΕΣ",
    route: "/services",
  },
  {
    id: 3,
    title: "ΕΡΓΑ",
    route: "/projects",
  },
  {
    id: 4,
    title: "ΕΤΑΙΡΕΙΑ",
    route: "/about-us",
  },
  {
    id: 5,
    title: "ΕΠΙΚΟΙΝΩΝΙΑ",
    route: "/contact-us",
  },
];

export const ourServicesData = [
  {
    id: 0,
    title: "ΟΛΟΚΛΗΡΩΜΕΝΕΣ ΕΓΚΑΤΑΣΤΑΣΕΙΣ ΤΡΟΦΟΔΟΤΗΣΗΣ ΥΓΡΑΕΡΙΟΥ",
    type: 1,
    content:
      "Η εταιρεία διατηρεί πολύχρονη συνεργασία με μερικές από τις μεγαλύτερες εταιρείες προμήθειας υγραερίου (PetroGaz, CORAL GAS, EKO) έχοντας αναλάβει έργα τροφοδοσίας μεγάλων εργοστασίων ανά την Ελλάδα.",
  },
  {
    id: 1,
    title: "ΟΛΟΚΛΗΡΩΜΕΝΕΣ ΕΓΚΑΤΑΣΤΑΣΕΙΣ ΤΡΟΦΟΔΟΤΗΣΗΣ ΦΥΣΙΚΟΥ ΑΕΡΙΟΥ",
    type: 1,
    content:
      "Κατασκευή εσωτερικών δικτύων τροφοδοσίας Φυσικού Αερίου από την έλευση αυτού στην Ελλάδα στον βιομηχανικό – βιοτεχνικό τομέα.",
  },
  {
    id: 2,
    title: "ΕΞΕΙΔΙΚΕΥΜΕΝΑ ΕΡΓΑ",
    type: 2,
    content: [
      {
        item: "Αποξηλώσεις, μεταφορές και επανασυναρμολογήσεις κάθε είδους μηχανήματος παραγωγής και μηχανολογική σύνδεση των μηχανημάτων αυτών εφόσον απαιτηθεί.",
        id: 1,
      },
      {
        item: "Συγκρότηση όλου του μηχανολογικού εξοπλισμού επεξεργασίας βιοντηζέλ και κατασκευή όλων των απαιτούμενων σωληνογραμμών σύνδεσης και λειτουργίας.",
        id: 2,
      },
      {
        item: "Συγκρότηση μονάδος παραγωγής βιοαερίου από το τμήμα εισόδου του αποβλήτου έως και την καύση του παραγόμενου βιοαερίου (δίκτυα μεταφοράς αποβλήτου, δίκτυα βιοαερίου, φίλτρα, δίκτυα νερού κλπ.)",
        id: 3,
      },
      {
        item: "Συγκρότηση μονάδων επεξεργασίας υποπροϊόντων σφαγής (Rendering).",
        id: 4,
      },
    ],
  },
  {
    id: 3,
    title: "ΟΛΟΚΛΗΡΩΜΕΝΕΣ ΕΓΚΑΤΑΣΤΑΣΕΙΣ ΚΥΚΛΩΜΑΤΩΝ ΤΡΟΦΟΔΟΤΗΣΗΣ ΑΤΜΟΥ",
    type: 1,
    content:
      "Συγκρότηση εκ θεμελίων εγκαταστάσεων τροφοδότησης ατμού σε ένα εύρη φάσμα διαφορετικών αντικειμένων (βαφεία υφασμάτων, τυπωτήρια, γουνοποιίες, γαλακτοβιομηχανίες, βιομηχανίες παραγωγής χυμών, βυρσοδεψία, μονάδες επεξεργασίας κρεάτων, βιομηχανίες μπισκότων και λοιπών ειδών διατροφής κλπ.). Πραγματοποίηση συντηρήσεων και επεκτάσεων σε ένα μεγάλο αριθμό υφιστάμενων εγκαταστάσεων.",
  },
  {
    id: 4,
    title: "ΕΓΚΑΤΑΣΤΑΣΕΙΣ ΘΕΡΜΑΝΣΗΣ ΜΕ ΧΡΗΣΗ ΔΙΑΘΕΡΜΙΚΟΥ ΛΑΔΙΟΥ",
    type: 1,
    content:
      "Συγκρότηση εκ θεμελίων εγκαταστάσεων τροφοδότησης διαθερμικού λαδίου σε βαφεία – τυπωτήρια υφασμάτων, σε στεγνωτήρια βιομηχανικών πλυντηρίων, σε μονάδες παραγωγής φορμάικας, σε φούρνος ψωμιού και σε πολλές άλλες διεργασίες. Πραγματοποίηση συντηρήσεων και επεκτάσεων σε ένα μεγάλο αριθμό υφιστάμενων εγκαταστάσεων.",
  },
  {
    id: 5,
    type: 1,
    title:
      "ΕΓΚΑΤΑΣΤΑΣΕΙΣ ΨΥΞΗΣ ΜΕ ΤΗ ΧΡΗΣΗ ΜΕΙΓΜΑΤΩΝ ΠΡΟΠΥΛΕΝΟΓΛΥΚΟΛΗΣH Ή ΜΕ ΤΗ ΧΡΗΣΗ ΑΜΜΩΝΙΑΣ",
    content:
      "Κατασκευή ή επέκταση κυκλωμάτων ψύξης που χρησιμοποιούν ως ψυκτικό μέσο διαφορετικά μείγματα προπυλενογλυκόλης ή αμμωνία. Έργα βιομηχανικής ψύξης που αφορούν κυρίως μονάδες τροφίμων με τμήματα συντήρησης – ψύξης επεξεργαζόμενων προϊόντων.",
  },
  {
    id: 6,
    title:
      "ΕΓΚΑΤΑΣΤΑΣΕΙΣ ΚΥΚΛΩΜΑΤΩΝ ΥΓΡΑΕΡΓΙΟΥ ΠΛΗΡΩΣΗΣ ΠΕΡΟΝΟΦΟΡΩΝ & Ι.Χ ΑΥΤΟΚΙΝΗΤΩΝ",
    type: 2,
    content: [
      {
        item: "Κατασκευή εγκαταστάσεων πλήρωσης περονοφόρων οχημάτων (ΚΛΑΡΚ) με υγραέριο, σε εργοστασιακές μονάδες, με δυνατότητες τροφοδότησης ανάλογες των απαιτήσεων κάθε πελάτη.",
        id: 1,
      },
      {
        item: "Κατασκευή εγκαταστάσεων πλήρωσης αυτοκινήτων (autogas) σε πρατήρια μεικτών καυσίμων.",
        id: 2,
      },
    ],
  },
  {
    id: 7,
    title: "ΕΡΓΑ ΕΞΟΙΚΟΝΟΜΗΣΗΣ ΕΝΕΡΓΕΙΑΣ",
    type: 2,
    content: [
      {
        item: "Εγκαταστάσεις θέρμανσης βιομηχανικών χώρων με χρήση λέβητα στερεών καυσίμων και αερόθερμα νερού – νερού ή ατμού – νερού.",
        id: 1,
      },
      {
        item: "Εγκαταστάσεις θέρμανσης βιομηχανικών χώρων με θέρμανση με κάτοπτρα ακτινοβολίας αερίου.",
        id: 2,
      },
      {
        item: "Εγκαταστάσεις θέρμανσης – ψύξης με την χρήση αντλιών θερμότητας.",
        id: 3,
      },
      {
        item: "Εγκαταστάσεις θέρμανσης νερών χρήσης με την χρήση ηλιακών συλλεκτών.",
        id: 4,
      },
      {
        item: "Εγκαταστάσεις ψύξης χώρων με την χρήση ηλιακών συλλεκτών και ψυκτών απορρόφησης.",
        id: 5,
      },
      {
        item: "Εγκαταστάσεις θέρμανσης – ψύξης - ηλεκτρισμού, συμπαραγωγής και τρι-παραγωγής.",
        id: 6,
      },
    ],
  },
];
