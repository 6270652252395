// React
import { useState } from "react";

// Helmet package
import { Helmet } from "react-helmet-async";

//Import components
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import SectionHeader from "../../components/section-header/SectionHeader";
import ProjectItem from "./project-item/ProjectItem";

// Import styles
import { ProjectsContainer, SectionContainer } from "./Projects.styles";

// Import data
import { ourProjectsData } from "../../data";

// imagePath={`${process.env.PUBLIC_URL}${ourProjectsData[i].imagePath[0]}`}

const breadcrumbLinks = [
  {
    id: 1,
    title: "Αρχική",
    route: "/",
  },
  {
    id: 2,
    title: "Τα Έργα μας",
    route: "/projects",
  },
];

const Projects = () => {
  const [isOpenArr, setIsOpenArr] = useState(
    Array(ourProjectsData.length).fill(false)
  );

  const toggleButtonHandler = (index) => {
    const newIsOpenArr = [...isOpenArr];
    newIsOpenArr[index] = !isOpenArr[index];
    setIsOpenArr([...newIsOpenArr]);
  };

  const showProjects = () => {
    return ourProjectsData.map((p) => {
      return (
        <ProjectItem
          key={p.id}
          projectId={p.id}
          title={p.title}
          imageArr={p.imagePath}
          isActive={isOpenArr[p.id]}
          toggleButton={toggleButtonHandler}
        ></ProjectItem>
      );
    });
  };

  return (
    <ProjectsContainer>
      <Helmet>
        <title>Gas Team Κατασκευαστική | Τα Έργα μας</title>
      </Helmet>
      <Breadcrumb links={breadcrumbLinks} />
      <SectionHeader title="ΤΑ ΕΡΓΑ ΜΑΣ" size="large" />
      <SectionContainer>{showProjects()}</SectionContainer>
    </ProjectsContainer>
  );
};

export default Projects;
