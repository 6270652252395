import styled from "styled-components";
import {
  secondaryColor,
  mainFontColor,
  backGroundColor,
  heroSectionFontColor,
} from "../../utils/styleVariables";

export const BaseButton = styled.button`
  min-width: 16.5rem;
  width: auto;
  height: 4rem;
  padding: 0 2.4rem 0 2.4rem;
  margin: 0 auto;

  border: 0.1rem solid ${secondaryColor}};
  border-radius: 0.4rem;
  background-color: ${secondaryColor};
  font-size: 1.6rem;
  font-weight: 600;

  color: ${mainFontColor};
  cursor: pointer;

  a{
    color: ${mainFontColor};
    text-decoration: none;
  }

  :hover {
    background-color: ${backGroundColor};
    color: black;
  }
`;

export const TransparentButton = styled(BaseButton)`
  background-color: transparent;
  border-color: ${heroSectionFontColor};
  color: ${heroSectionFontColor};

  a {
    color: ${heroSectionFontColor};
    text-decoration: none;
  }

  :hover {
    background-color: ${heroSectionFontColor};
    color: ${mainFontColor};

    a {
      color: ${mainFontColor};
    }
  }
`;
