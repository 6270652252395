// Import components
import LinkButton from "../../link-button/LinkButton";

// Import styles
import { Container, LabelContainer } from "./HeroSection.styles";

const HeroSection = () => {
  return (
    <Container imagePath={`${process.env.PUBLIC_URL}/images/hero-img.webp`}>
      <LabelContainer>
        <h1>ΑΞΙOΠΙΣΤΕΣ ΥΠΗΡΕΣIΕΣ </h1>
        <p>
          Σχεδίαση και υλοποίηση ενεργειακών και λοιπών σύνθετων μηχανολογικών
          έργων
        </p>
        <LinkButton to="contact-us" buttonType="transparent">
          ΕΠΙΚΟΙΝΩΝΙΑ
        </LinkButton>
      </LabelContainer>
    </Container>
  );
};

export default HeroSection;
