// Import components
import SectionHeader from "../../section-header/SectionHeader";
import WhyUsItem from "../why-us-item/WhyUsItem";
import LinkButton from "../../link-button/LinkButton";

// Import icons
import { ReactComponent as EnginearIcon } from "../../../assets/svg/engineering.svg";
import { ReactComponent as CheckIcon } from "../../../assets/svg/check.svg";
import { ReactComponent as SatisfiedIcon } from "../../../assets/svg/satisfied.svg";

// Import styles
import { WhyUsContainer, WhyUsItemContainer } from "./WhyUs.styles";

const WhyUs = () => {
  return (
    <WhyUsContainer>
      <SectionHeader title="ΓΙΑΤΙ ΝΑ ΜΑΣ ΕΠΙΛΕΞΕΤΕ" size="medium">
        <p>
          Έχοντας περισσότερα από 40 χρόνια στο χώρο των κατασκευών, παρέχουμε
          ένα ευρύ φάσμα ηλεκτρομηχανολογικών έργων και υπηρεσιών.
        </p>
      </SectionHeader>
      <WhyUsItemContainer>
        <WhyUsItem
          title="ΑΣΦΑΛΕΙΑ ΚΑΙ ΑΞΙΟΠΙΣΤΙΑ"
          content="Εφαρμόζουμε υπεύθυνα τους κανόνες ασφαλείας και χρησιμοποιούμε υψηλής ποιότητας υλικά "
        >
          <CheckIcon />
        </WhyUsItem>
        <WhyUsItem
          title="ΕΞΕΙΔΙΚΕΥΜΕΝΟ ΠΡΟΣΩΠΙΚΟ"
          content="Εξειδικευμένο και άρτια εκπαιδευμένο τεχνικό προσωπικό"
        >
          <EnginearIcon />
        </WhyUsItem>
        <WhyUsItem
          title="ΑΜΕΣΗ ΕΞΥΠΗΡΕΤΗΣΗ"
          content="Θεωρώντας την κάθε εργασία προσωπική υπόθεση παρέχουμε άμεση και αποτελεσματική εξυπηρέτηση"
        >
          <SatisfiedIcon />
        </WhyUsItem>
      </WhyUsItemContainer>
      <LinkButton to="about-us">ΜΑΘΕΤΕ ΠΕΡΙΣΣΟΤΕΡΑ</LinkButton>
    </WhyUsContainer>
  );
};

export default WhyUs;
