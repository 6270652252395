// Helmet package
import { Helmet } from "react-helmet-async";

// Import styles
import {
  CookiesPolicyContainer,
  SectionContainer,
  CookiesTable,
  CookiesTableHeader,
  CookiesTableRow,
} from "./CookiesPolicy.styles";

//Import components
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import SectionHeader from "../../components/section-header/SectionHeader";

const breadcrumbLinks = [
  {
    id: 1,
    title: "Αρχική",
    route: "/",
  },
  {
    id: 2,
    title: "Πολιτική Cookies",
    route: "/about-us",
  },
];

const CookiesPolicy = () => {
  return (
    <CookiesPolicyContainer>
      <Helmet>
        <title>Gas Team Κατασκευαστική | Πολιτική Cookies</title>
      </Helmet>
      <Breadcrumb links={breadcrumbLinks} />
      <SectionHeader title="ΠΟΛΙΤΙΚΗ COOKIES" size="large" />
      <SectionContainer>
        <h3>Τι είναι τα Cookies</h3>
        <p>
          Tα cookies είναι μικρά αρχεία που αποθηκεύονται στον σκληρό δίσκο του
          υπολογιστή ή σε άλλη ηλεκτρονική συσκευή με την οποία επισκεπτόμαστε
          μια ιστοσελίδα στο Διαδίκτυο. Τα cookies περιέχουν πληροφορίες σχετικά
          με την επίσκεψη και την περιήγηση του χρήστη στην ιστοσελίδα, και
          επιτρέπουν στον ιστότοπο να αναγνωρίζει τον χρήστη και τις προτιμήσεις
          του στην επόμενη επίσκεψή του. Τα cookies επιτρέπουν επίσης στον
          ιστότοπο να παρέχει προσαρμοσμένες εμπειρίες χρήσης και διαφημίσεις
          στους χρήστες. Υπάρχουν διάφορες κατηγορίες cookies, ανάλογα με τον
          σκοπό και τον τρόπο λειτουργίας τους.
        </p>
        <p>
          Στον ιστότοπό μας χρησιμοποιούμε cookies, σύμφωνα με τις συνήθεις
          πρακτικές του διαδικτύου, με σκοπό να βελτιώσουμε την πρόσβαση και την
          περιήγησή σας στην ιστοσελίδα μας. Χρησιμοποιούμε διάφορες κατηγορίες
          cookies, συμπεριλαμβανομένων των "αυστηρώς απαραίτητων" cookies που
          επιτρέπουν τη βασική λειτουργία της ιστοσελίδας μας, καθώς και cookies
          επιδόσεων για τη συλλογή πληροφοριών σχετικά με την επισκεψιμότητα και
          τον τρόπο που περιηγούνται οι χρήστες στην ιστοσελίδα μας.
        </p>
        <h3>Τα cookies που χρησιμοποιούμε</h3>
        <h4>Απαραίτητα cookies</h4>
        <CookiesTable>
          <CookiesTableHeader>
            <h5>Όνομα</h5>
            <h5>Πηγή</h5>
            <h5>Σκοπός</h5>
          </CookiesTableHeader>
          <CookiesTableRow>
            <span>cookies-consent</span>
            <span>Gas Team Con</span>
            <p>
              Χρησιμοποιείται για την αποθήκευση της επιλογής του χρήστη σχετικά
              με την αποδοχή ή όχι των cookies Αυτό διασφαλίζει ότι το μήνυμα
              σχετικά με τα cookies δεν θα εμφανιστεί ξανά κατά τη διάρκεια της
              επίσκεψης του χρήστη στην ιστοσελίδα και ότι η επιλογή του χρήστη
              θα ληφθεί υπόψη κατά την περαιτέρω περιήγησή του στην ιστοσελίδα.
            </p>
          </CookiesTableRow>
          <CookiesTableRow>
            <span>firebase-installations-store</span>
            <span>Google Firebase</span>
            <p>
              Χρησιμοποιείται για να εξασφαλίσει τη λειτουργικότητα της
              υπηρεσίας Firebase και να βελτιώσει την εμπειρία χρήστη στην
              εφαρμογή. Συγκεκριμένα, αυτό το Cookie αποθηκεύει τα δεδομένα
              εγκατάστασης της εφαρμογής, όπως ο αναγνωριστικός κωδικός της
              εγκατάστασης και άλλες πληροφορίες που αφορούν τη συσκευή και την
              εφαρμογή.
            </p>
          </CookiesTableRow>
          <CookiesTableRow>
            <span>firebase-installations-database</span>
            <span>Google Firebase</span>
            <p>
              Χρησιμοποιείται για να εξασφαλίσει τη λειτουργικότητα της
              υπηρεσίας Firebase και να βελτιώσει την εμπειρία χρήστη στην
              εφαρμογή. Συγκεκριμένα, αυτό το Cookie αποθηκεύει τα δεδομένα της
              εγκατάστασης στη βάση δεδομένων του Firebase.
            </p>
          </CookiesTableRow>
        </CookiesTable>
        <h4>Cookies επιδόσεων</h4>
        <CookiesTable>
          <CookiesTableHeader>
            <h5>Όνομα</h5>
            <h5>Πηγή</h5>
            <h5>Σκοπός</h5>
          </CookiesTableHeader>
          <CookiesTableRow>
            <span>_ga</span>
            <span>Google Analytics</span>
            <p>
              Αυτό το cookie χρησιμοποιείται για τη διάκριση μοναδικών χρηστών,
              αναθέτοντας έναν τυχαίο αριθμό σε κάθε χρήστη. Ο σκοπός του είναι
              να υπολογίσει τον αριθμό των επισκεπτών στον ιστότοπο.
            </p>
          </CookiesTableRow>
          <CookiesTableRow>
            <span>_gid</span>
            <span>Google Analytics</span>
            <p>
              Αυτό το cookie χρησιμοποιείται για να αναγνωρίζει τον χρήστη κατά
              τη διάρκεια μιας συγκεκριμένης συνεδρίας. Ο σκοπός του είναι να
              καταγράφει την επίσκεψη του χρήστη στον ιστότοπο.
            </p>
          </CookiesTableRow>
        </CookiesTable>
        <h3>Πώς να απενεργοποιήσετε τα cookies;</h3>
        <ul>
          Μπορείτε να διαχειριστείτε τα cookies, σύμφωνα με τις οδηγίες που
          παρέχονται ανά browser στους παρακάτω συνδέσμους:
        </ul>
        <li>
          <a
            href="https://support.google.com/chrome/answer/95647?hl=el"
            target="_blank"
            rel="noreferrer"
          >
            Google Chrome
          </a>
        </li>
        <li>
          <a
            href="https://support.microsoft.com/el-gr/topic/%CF%84%CF%81%CF%8C%CF%80%CE%BF%CF%82-%CE%B4%CE%B9%CE%B1%CE%B3%CF%81%CE%B1%CF%86%CE%AE%CF%82-%CE%B1%CF%81%CF%87%CE%B5%CE%AF%CF%89%CE%BD-cookie-%CF%83%CF%84%CE%BF%CE%BD-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc"
            target="_blank"
            rel="noreferrer"
          >
            Internet Explorer
          </a>
        </li>
        <li>
          <a
            href="https://support.microsoft.com/el-gr/microsoft-edge/%CE%B4%CE%B9%CE%B1%CE%B3%CF%81%CE%B1%CF%86%CE%AE-cookies-%CE%B1%CF%80%CF%8C-%CF%84%CE%BF%CE%BD-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
            target="_blank"
            rel="noreferrer"
          >
            Microsoft Edge
          </a>
        </li>
        <li>
          <a
            href="https://support.mozilla.org/el/kb/enisxymenh-prostasia-apo-katagrafh-firefox?redirectslug=energopoihsh-apenergopoihsh-cookies-parakoloy8hsh-protimhsewn&redirectlocale=el"
            target="_blank"
            rel="noreferrer"
          >
            Mozilla Firefox
          </a>
        </li>
        <li>
          <a
            href="https://help.opera.com/en/latest/web-preferences/#cookies"
            target="_blank"
            rel="noreferrer"
          >
            Opera
          </a>
        </li>
        <li>
          <a
            href="https://support.apple.com/el-gr/HT201265"
            target="_blank"
            rel="noreferrer"
          >
            Safari
          </a>
        </li>
      </SectionContainer>
    </CookiesPolicyContainer>
  );
};

export default CookiesPolicy;
