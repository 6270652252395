// Helmet package
import { Helmet } from "react-helmet-async";

// Import components
import LinkButton from "../../components/link-button/LinkButton";

// Import styles
import { Container, SectionContainer } from "./PageNotFound.styles";

// Icons
import { ReactComponent as ErrorIcon } from "../../assets/svg/error_48.svg";

const PageNotFound = () => {
  return (
    <Container>
      <Helmet>
        <title>Gas Team Κατασκευαστική | Η σελίδα δεν βρέθηκε</title>
      </Helmet>
      <SectionContainer>
        <ErrorIcon />
        <h1>Η σελίδα που πληκτρολογήσατε δεν βρέθηκε</h1>
        <LinkButton to="/">ΑΡΧΙΚΗ ΣΕΛΙΔΑ</LinkButton>
      </SectionContainer>
    </Container>
  );
};

export default PageNotFound;
