import styled from "styled-components";
import {
  mainColor,
  backGroundColor,
  mainFontColor,
  secondaryColor,
} from "../../utils/styleVariables";

export const FormInputContainer = styled.div`
  margin-bottom: 2.4rem;
  display: flex;
  flex-direction: column;

  color: ${mainFontColor};
  font-size: 1.6rem;
`;

export const Input = styled.input`
  font-size: 1.6rem;
  background-color: ${backGroundColor};
  padding: 1rem 1rem 1rem 1.2rem;
  margin-top: 1.2rem;
  display: block;
  width: 100%;
  border: 0.1rem solid ${mainColor};
  border-radius: 0.6rem;

  &:focus {
    outline: none;
  }
`;

export const TextArea = styled.textarea`
  margin-top: 1.2rem;
  font-size: 1.6rem;
  background-color: ${backGroundColor};
  padding: 1rem 1rem 1rem 1.2rem;
  display: block;
  width: 100%;
  border: 0.1rem solid ${mainColor};
  border-radius: 0.6rem;

  &:focus {
    outline: none;
  }
`;

export const ErrorMessage = styled.span`
  margin-top: 0.8rem;
  font-size: 1.6rem;
  color: ${secondaryColor};
  font-style: italic;
`;
