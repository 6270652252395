import styled from "styled-components";
import { mainColorTint } from "../../../utils/styleVariables";

export const OurProjectsContainer = styled.div`
  margin: 0 1.6rem;
  display: flex;
  flex-direction: column;
  width: 28rem;
  height: 28rem;
  // background-color: #ffffff;
  border-radius: 0 0 0.4rem 0.4rem;
  box-shadow: 0 0.1rem 1.8rem ${mainColorTint};

  img {
    height: 20rem;
    border-radius: 0.4rem 0.4rem 0 0;
  }

  @media (max-width: 30em) {
    width: 24rem;
    height: 24em;

    img {
      height: 16rem;
    }
  }

  @media (max-width: 23em) {
    width: 19rem;
    height: 22em;

    img {
      height: 14rem;
    }
  }
`;
