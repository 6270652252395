import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

import {
  topBarBgColor,
  secondaryFontColor,
} from "../../../utils/styleVariables";

const appear = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 60vw;
  }
`;

export const MobileMenuContainer = styled.div`
  display: none;
  position: relative;
  background-color: ${topBarBgColor};
  svg {
    fill: ${secondaryFontColor};
    margin: 0.2rem 2.4rem;
    cursor: pointer;
  }
  @media (max-width: 33em) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const MobileSideBarContainer = styled.nav`
  position: absolute;
  height: 1200vh;
  z-index: 5;
  padding: 2.4rem;

  display: flex;
  flex-direction: column;
  background-color: ${topBarBgColor};
  box-shadow: -0.2rem 0.4rem 1.2rem #000000;

  animation: 0.4s ${appear} ease-out forwards;

  svg {
    fill: ${secondaryFontColor};
    cursor: pointer;
    margin: 0;
  }
`;

export const NavLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NavLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0;
  margin-top: 1.6rem;
  text-decoration: none;
  font-size: 1.4rem;
  color: ${secondaryFontColor};

  svg {
    margin: 0rem;
  }
`;

export const Divider = styled.div`
  border-bottom: 0.2rem solid ${secondaryFontColor};
  padding: 0;
  margin: 0;
  opacity: 0.1;
`;
