// React router
import { Link } from "react-router-dom";
// Import components
import Map from "../map/Map";

// Import styles
import {
  MainContainer,
  FooterContainer,
  LogoContentContainer,
  ContentContainer,
  WhereToFindUs,
  WhereToFindUsItem,
  Info,
  InfoItem,
  Bullet,
  Copyright,
} from "./Footer.styles";

// White logo
import logo from "../../assets/logo-white.png";

// SVG Icons
import { ReactComponent as MailIcon } from "../../assets/svg/mail.svg";
import { ReactComponent as PhoneIcon } from "../../assets/svg/phone.svg";
import { ReactComponent as TimeIcon } from "../../assets/svg/time.svg";
import { ReactComponent as LocationIcon } from "../../assets/svg/location.svg";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <MainContainer>
      <FooterContainer>
        <Map />
        <LogoContentContainer>
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
          <ContentContainer>
            <WhereToFindUs>
              <h3>ΘΑ ΜΑΣ ΒΡΕΙΤΕ</h3>
              <WhereToFindUsItem>
                <LocationIcon />
                Νεοχωρούδα, Θεσσαλονίκη, 54500
              </WhereToFindUsItem>
              <WhereToFindUsItem>
                <PhoneIcon /> <a href="tel:2310788485">2310 788-485</a>
              </WhereToFindUsItem>
              <WhereToFindUsItem>
                <MailIcon />
                gasteam@otenet.gr
              </WhereToFindUsItem>
              <WhereToFindUsItem>
                <TimeIcon />
                ΔΕΥ-ΠΑΡ: 08:30 - 16:00
              </WhereToFindUsItem>
            </WhereToFindUs>
            <Info>
              <h3>ΠΛΗΡΟΦΟΡΙΕΣ</h3>
              <InfoItem>
                <Bullet />
                <Link to="services">Υπηρεσίες</Link>
              </InfoItem>
              <InfoItem>
                <Bullet />
                <Link to="contact-us">Επικοινωνία</Link>
              </InfoItem>
              <InfoItem>
                <Bullet />
                <Link to="about-us">Σχετικά με Εμάς</Link>
              </InfoItem>
              <InfoItem>
                <Bullet />
                <Link to="cookies-policy">Πολιτική Cookies</Link>
              </InfoItem>
            </Info>
          </ContentContainer>
        </LogoContentContainer>
        <Copyright>
          <span>
            Copyright &#169;{" "}
            {currentYear - 2023 === 0 ? "2023" : `2023 - ${currentYear}`} GAS
            TEAM ΚΑΤΑΣΚΕΥΑΣΤΙΚΗ |
          </span>
          <Link>Developed by SD</Link>
        </Copyright>
      </FooterContainer>
    </MainContainer>
  );
};

export default Footer;
