import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  secondaryColor,
  mainFontColor,
  backGroundColor,
  heroSectionFontColor,
} from "../../utils/styleVariables";

export const BaseLinkButton = styled(Link)`
  min-width: 16.5rem;
  display: block;
  width: auto;
  padding: 1.2rem 2.4rem;
  margin: 0 auto;
  
  border: 0.1rem solid ${secondaryColor}};
  border-radius: 0.4rem;
  background-color: ${secondaryColor};
  
  font-size: 1.6rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  color: ${mainFontColor};
  cursor: pointer;
  
  :hover {
    background-color: ${backGroundColor};
    color: black;
  }
`;

export const TransparentLinkButton = styled(BaseLinkButton)`
  background-color: transparent;
  border-color: ${heroSectionFontColor};
  color: ${heroSectionFontColor};

  text-decoration: none;
  vertical-align: middle;
  text-decoration: none;

  :hover {
    background-color: ${heroSectionFontColor};
    color: ${mainFontColor};
  }
`;

export const SmallBaseLinkButton = styled(BaseLinkButton)`
  min-width: 0rem;
`;
