import styled from "styled-components";
import { mainFontColor } from "../../utils/styleVariables";

const Header = styled.div`
  text-align: center;
  div {
    border: 0.1rem solid rgba(255, 135, 135, 0.4);
  }
`;

export const SmallHeader = styled(Header)`
  margin: 1.8rem auto 0rem auto;
  color: ${mainFontColor};
  h2 {
    padding: 0 1rem;
    font-size: 1.6rem;
    letter-spacing: 0.05rem;
  }

  div {
    width: 8rem;
    margin: 1rem auto 0.8rem auto;
  }

  p {
    padding: 0 1rem;
    max-width: 60rem;
    font-size: 1.6rem;
    line-height: 1.2;
  }

  @media (max-width: 30em) {
    h2 {
      font-size: 1.6rem;
    }

    p {
      font-size: 1.6rem;
    }
  }
`;

export const MediumHeader = styled(Header)`
  margin: 3.2rem auto 0 auto;
  color: ${mainFontColor};
  h2 {
    padding: 0 2.4rem;
    font-size: 2rem;
    letter-spacing: 0.075rem;
  }

  div {
    width: 12rem;
    margin: 1.2rem auto 1.2rem auto;
  }

  p {
    padding: 0 1rem;
    max-width: 60rem;
    font-size: 1.6rem;
    line-height: 1.2;
  }

  @media (max-width: 48em) {
    h2 {
      font-size: 1.8rem;
      letter-spacing: 0.05rem;
    }
  }

  @media (max-width: 23em) {
    h2 {
      font-size: 1.6rem;
    }
  }
`;

export const LargeHeader = styled(Header)`
  margin: 2.4rem auto;
  h2 {
    padding: 0 2.4rem;
    font-size: 2.4rem;
    letter-spacing: 0.075rem;
  }

  div {
    width: 12rem;
    margin: 1.2rem auto 3rem auto;
  }

  @media (max-width: 48em) {
    h2 {
      font-size: 2rem;
      letter-spacing: 0.05rem;
    }
  }

  @media (max-width: 23em) {
    h2 {
      font-size: 1.8rem;
    }
  }
`;
