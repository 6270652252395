// Import styles
import {
  ProjectItemContainer,
  ProjectItemTitle,
  ImageContainer,
} from "./ProjectItem.styles";

// Icon
import { ReactComponent as RightArrow } from "../../../assets/svg/arrow_right.svg";
import { ReactComponent as DownArrow } from "../../../assets/svg/arrow_down.svg";

const ProjectItem = ({
  projectId,
  title,
  imageArr,
  isActive,
  toggleButton,
}) => {
  const showImages = () => {
    return imageArr.map((i) => {
      return <img key={i} src={`${process.env.PUBLIC_URL}${i}`} alt={title} />;
    });
  };

  return (
    <ProjectItemContainer>
      <ProjectItemTitle>
        {isActive ? (
          <DownArrow onClick={() => toggleButton(projectId)} />
        ) : (
          <RightArrow onClick={() => toggleButton(projectId)} />
        )}
        <h3>{title}</h3>
      </ProjectItemTitle>
      {isActive && <ImageContainer>{showImages()}</ImageContainer>}
    </ProjectItemContainer>
  );
};

export default ProjectItem;
