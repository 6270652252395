import styled from "styled-components";

export const OurServicesContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.2rem 2.4rem 4.4rem 2.4rem;
  padding: 0 7.2rem 0 7.2rem;

  @media (max-width: 66em) {
    padding: 0 2.4rem 0 2.4em;
  }
`;
export const OurServicesItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2.4rem 0 3.6rem 0;

  @media (max-width: 72em) {
    justify-content: space-evenly;
  }
`;
