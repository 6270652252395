import styled from "styled-components";
import { mainFontColor } from "../../utils/styleVariables";

export const BreadcrumbContainer = styled.nav`
  margin: 1.8rem 2.4rem 2.4rem 4.8rem;
  font-size: 1.2rem;
  color: ${mainFontColor};

  span {
    padding: 0 0.2rem;
  }

  a {
    color: ${mainFontColor};
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 33em) {
    display: none;
  }
`;
