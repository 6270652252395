// React router
import { Link } from "react-router-dom";

// Import components
import NavLink from "../nav-link/NavLink";

// Import styles
import { Container, NavLinksContainer } from "./StickyNavBar.styles";

// Gasteam Logo
import logo from "../../../assets/logo.png";

const Navbar = ({ navLinksList }) => {
  return (
    <Container>
      <Link to="/">
        <img src={logo} alt="Logo" />
      </Link>
      <NavLinksContainer>
        {navLinksList.map((link) => (
          <NavLink key={link.id} link={link} />
        ))}
      </NavLinksContainer>
    </Container>
  );
};

export default Navbar;
