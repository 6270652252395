import styled from "styled-components";
import { mainFontColor, secondaryColor } from "../../utils/styleVariables";

export const AboutUsContainer = styled.div`
  max-width: 136.6rem;
  margin: 0 auto;
`;

export const SectionContainer = styled.main`
  max-width: 80rem;
  margin: 1.2rem auto 9.6rem auto;

  display: flex;
  flex-direction: column;

  line-height: 1.2;
  letter-spacing: 0.05rem;
  font-size: 1.6rem;
  color: ${mainFontColor};

  p {
    text-align: justify;
    margin-bottom: 1.2rem;
  }

  span {
    font-weight: 500;
  }

  ul {
    margin: 1.2rem 2.4rem 0 2.4rem;
    list-style-type: none;
    display: flex;
    flex-direction: column;
  }

  li {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: 2.4rem 1fr;
    grid-template-rows: 2.4rem 1fr;
    column-gap: 1.2rem;
    align-items: center;

    svg {
      grid-area: 1 / 1 / 2 / 2;
      fill: ${secondaryColor};
    }

    p {
      grid-area: 1 / 2 / 3 / 3;
      text-align: start;
    }
  }

  @media (max-width: 56em) {
    margin: 2.4rem 2.4rem 9.6rem 2.4rem;
  }

  @media (max-width: 26em) {
    margin: 1.2rem 2.4rem 9.6rem 2.4rem;

    p {
      word-break: break-word;
    }

    ul {
      margin: 1.2rem 1.2rem 0 0rem;
    }
  }
`;
