// React
import { Fragment } from "react";
//React router
import { Link } from "react-router-dom";

//Import styles
import { BreadcrumbContainer } from "./Breadcrumb.styles";

const Breadcrumb = ({ links }) => {
  const showLinks = () => {
    const lastIndex = links.length - 1;
    return links.map((l, i) => {
      if (i < lastIndex) {
        return (
          <Fragment key={l.id}>
            <Link to={l.route}>{l.title}</Link>
            <span>/</span>
          </Fragment>
        );
      }
      return (
        <Link key={l.id} to={l.route}>
          {l.title}
        </Link>
      );
    });
  };

  return <BreadcrumbContainer>{showLinks()}</BreadcrumbContainer>;
};

export default Breadcrumb;
