// Import components
import SectionHeader from "../../section-header/SectionHeader";

// Import styles
import { OurServicesContainer, IconContainer } from "./OurServicesItem.styles";

const OurServicesItem = ({ children, title, content, imagePath }) => {
  return (
    <OurServicesContainer>
      <img src={imagePath} alt={title} />
      <IconContainer>{children}</IconContainer>
      <SectionHeader size="small" title={title}>
        <p>{content}</p>
      </SectionHeader>
    </OurServicesContainer>
  );
};

export default OurServicesItem;
