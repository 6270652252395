import styled from "styled-components";

export const ProjectsContainer = styled.div`
  max-width: 136.6rem;
  margin: 0 auto;
`;
export const SectionContainer = styled.main`
  display: flex;
  flex-direction: column;

  margin: 2.4rem 4.8rem 9.6rem 4.8rem;

  @media (max-width: 48em) {
    margin: 2.4rem 2.4rem 9.6rem 2.4rem;
  }
`;
