import styled, { keyframes } from "styled-components";

const slidedown = keyframes` 
  0% {
    top: -20%;
  }

  100% {
    top: 0%;
  }
  `;

export const Container = styled.nav`
  margin: 0 auto;
  max-width: 136.6rem;
  display: flex;
  flex-wrap: wrap;
  padding: 1.2rem 2.4rem;

  img {
    height: 9.8rem;
    margin: 0 2.4rem;
  }

  @media (min-width: 33.01em) {
    position: fixed;
    transform: translate(-50%, 0);
    left: 50%;
    top: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0 0.1rem 1.8rem #adb5bd;
    background-color: #ffffff;
    animation: 0.6s ${slidedown} ease-out forwards;
  }

  @media (max-width: 56em) {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    img {
      height: 8rem;
    }
  }
`;

export const NavLinksContainer = styled.nav`
  align-self: end;
  display: flex;
  margin: 1.2rem auto 1.2rem 4.4rem;

  @media (max-width: 56em) {
    align-self: center;
    margin: 1.2rem 0 0 0;
  }
  @media (max-width: 33em) {
    display: none;
  }
`;
