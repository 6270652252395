// React
import { Fragment, useEffect, useContext, useState } from "react";

// React router
import { useLocation } from "react-router-dom";

// Import context
import { ScreenDimensionsContext } from "../../context/ScreenDimensionsContext";

// Import components
import TopBar from "./top-bar/TopBar";
import NavBar from "./nav-bar/NavBar";
import StickyNavBar from "./sticky-nav-bar/StickyNavBar";
import MobileNavBar from "./mobile-nav-bar/MobileNavBar";

// Import styles
import { TriangleDivider } from "./Header.styles";

// Main navigation links
import { navLinksList } from "../../data.js";

const Header = () => {
  // Screen Width state variable
  const { screenDimensions } = useContext(ScreenDimensionsContext);
  //  Boolean value to set if navbar must be sticky
  const [isSticky, setIsSticky] = useState(false);
  // Current path
  const { pathname } = useLocation();
  const isHomePage = pathname === "/";

  const screenHeight = screenDimensions.innerHeight;

  useEffect(() => {
    const scrollHandler = (e) => {
      const scrollTop = window.scrollY;
      if (screenHeight >= 460 && scrollTop > parseInt(screenHeight * 0.8)) {
        setIsSticky(true);
        return;
      }
      setIsSticky(false);
      return;
    };
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [screenHeight]);

  return (
    <Fragment>
      <TopBar />
      <NavBar navLinksList={navLinksList} />
      <MobileNavBar navLinksList={navLinksList} />
      {!isHomePage && <TriangleDivider />}
      {isSticky && <StickyNavBar navLinksList={navLinksList} />}
    </Fragment>
  );
};

export default Header;
