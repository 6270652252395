// Icons

import { ReactComponent as AddIcon } from "../../../assets/svg/add_24.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/svg/remove_24.svg";

// Import styles
import {
  ServiceItemContainer,
  ServiceTitle,
  ServiceContent,
  ServiceContentList,
  ServiceContentListItem,
  ServiceButton,
  ActiveServiceButton,
  Bullet,
} from "./ServiceItem.styles";

const ServiceItem = ({
  serviceId,
  title,
  content,
  isActive,
  toggleButton,
  type,
}) => {
  return (
    <ServiceItemContainer>
      {isActive ? (
        <ActiveServiceButton onClick={() => toggleButton(serviceId)}>
          <RemoveIcon />
        </ActiveServiceButton>
      ) : (
        <ServiceButton onClick={() => toggleButton(serviceId)}>
          <AddIcon />
        </ServiceButton>
      )}
      <ServiceTitle>{title}</ServiceTitle>
      {isActive &&
        (type === 1 ? (
          <ServiceContent>{content}</ServiceContent>
        ) : (
          <ServiceContentList>
            {content.map((i) => (
              <ServiceContentListItem key={i.id}>
                <Bullet />
                {i.item}
              </ServiceContentListItem>
            ))}
          </ServiceContentList>
        ))}
    </ServiceItemContainer>
  );
};

export default ServiceItem;
