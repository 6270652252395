// React router
import { useLocation } from "react-router-dom";

// Import styles
import { LinkItem } from "./NavLink.styles";

// Import icons
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow_drop_down.svg";

const NavLink = ({ link }) => {
  const { title, route } = link;

  // Current path
  const { pathname } = useLocation();

  const isCurrentLink = pathname === route;

  return (
    <LinkItem to={route}>
      {title}
      {isCurrentLink && <ArrowDown />}
    </LinkItem>
  );
};

export default NavLink;
