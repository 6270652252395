import styled from "styled-components";
import { mainFontColor } from "../../utils/styleVariables";

export const Container = styled.div`
  max-width: 136.6rem;
  margin: 0 auto;
`;

export const SectionContainer = styled.main`
  max-width: 80rem;
  margin: 4.8rem auto 9.6rem auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 9.6rem;
    height: auto;
    margin-bottom: 2.4rem;
    fill: ${mainFontColor};
  }

  h1 {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2.4rem;
  }

  @media (max-width: 33em) {
    margin: 2.4rem 2.4rem 9.6rem 2.4rem;
    svg {
      width: 4.8rem;
    }

    h1 {
      font-size: 1.6rem;
    }
  }
`;
