import styled, { keyframes } from "styled-components";

import { mainColor, secondaryFontColor } from "../../utils/styleVariables";

const animationDuration = "0.8s";
const animationTimingFun = "ease-out";

const slideUp = keyframes` 
  0% {
    bottom: -10rem;
  }
  100% { 
    bottom: 0;
  }
`;

const slideDown = keyframes` 
  0% {
    
    bottom: 0;
  }
  95% {
    bottom: -16rem;
  }

  100% {
    vissibility: hidden
  }
`;

export const CookiesConsentContainer = styled.div`
  position: fixed;
  transform: translate(-50%, 0);
  left: 50%;
  z-index: 10;

  max-width: 136.6rem;
  width: 100%;
  background-color: ${mainColor};
  opacity: 0.9;
  padding: 2.4rem;

  display: ${(props) => (props.displayBox ? "flex" : "none")};
  flex-direction: column;
  align-items: flex-start;

  h2 {
    margin-bottom: 2rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: ${secondaryFontColor};
  }

  p {
    margin-bottom: 2rem;
    line-height: 1.2;
    font-size: 1.6rem;
    color: ${secondaryFontColor};

    a {
      padding-left: 0.8rem;
      font-size: 1.6rem;
      font-weight: 600;
      color: ${secondaryFontColor};
    }
  }

  animation: ${(props) => (props.removeBox ? slideDown : slideUp)}
    ${animationDuration} ${animationTimingFun} forwards;
`;

export const ButtonsContainer = styled.div`
  align-self: flex-end;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  button {
    margin: 0 1.2rem;
  }

  @media (max-width: 27em) {
    justify-content: center;
    button {
      margin: 1.2rem;
    }
  }
`;
