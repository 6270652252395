import styled, { keyframes } from "styled-components";

import { heroSectionFontColor } from "../../../utils/styleVariables";

const rightMovement = keyframes`
  0% {
    transform: translateX(-24rem);
  }
  100% {
    transform: translateX(0);
  }
`;

const uptMovement = keyframes`
  0% {
    transform: translateY(20rem);
  }
  100% {
    transform: translateY(0);
  }
`;

const scale = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 70vh;
  position: relative;
  background-image: url(${(props) => props.imagePath});
  background-size: cover;
  background-position: center;

  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
`;

export const LabelContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 5%;
  transform: translate(0, -50%);

  display: flex;
  flex-direction: column;

  color: ${heroSectionFontColor};

  h1 {
    font-weight: 500;
    font-size: 2.2rem;
    animation: 0.8s ${rightMovement} ease-out forwards;
  }

  p {
    letter-spacing: 0.075rem;
    line-height: 1.4;
    width: 60%;
    margin-top: 2rem;
    font-size: 1.8rem;
    animation: 1.4s ${scale} ease-out forwards;
  }

  a {
    margin: 2rem auto 0 0;
    font-size: 1.8rem;
    animation: 1.2s ${uptMovement} ease-out forwards;
  }

  @media (max-width: 48em) {
    h1 {
      font-size: 2rem;
    }

    p {
      width: 70%;
      font-size: 1.6rem;
    }

    a {
      font-size: 1.6rem;
    }
  }

  @media (max-width: 40em) {
    p {
      width: 80%;
    }
  }

  @media (max-width: 36em) {
    h1 {
      font-size: 1.8rem;
    }
  }

  @media (max-width: 32em) {
  }

  @media (max-width: 24em) {
    h1 {
      font-size: 1.6rem;
    }
    width: 90%;
  }
`;
