export function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

// Emal validator
export function validateEmail(email) {
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email.match(emailRegex)) {
    return true;
  }
  return false;
}

// Phone number validator
export function ValidatePhone(phone) {
  const phoneRegex = /^\s*-?[0-9]{1,10}\s*$/;
  if (phone.match(phoneRegex)) {
    return true;
  }
  return false;
}
