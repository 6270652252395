import styled from "styled-components";

export const WhyUsItemContainer = styled.div`
  margin: 0 1.8rem;
  display: flex;
  flex-direction: column;
  width: 28rem;

  @media (max-width: 72em) {
    margin: 1.2rem 1.8rem;
  }

  @media (max-width: 30em) {
    width: 24rem;
  }
`;
