// Helmet package
import { Helmet } from "react-helmet-async";

// Import components
import HeroSection from "../../components/home/hero-section/HeroSection";
import WhyUs from "../../components/home/why-us/WhyUs";
import OurServices from "../../components/home/our-services/OurServices";
import OurProjects from "../../components/home/our-projects/OurProjects";
import OurCustomers from "../../components/home/our-customers/OurCustomers";

// Import styles
import { HomeContainer } from "./Home.styles";

const Home = () => {
  return (
    <HomeContainer>
      <Helmet>
        <title>Gas Team Κατασκευαστική | Αρχική</title>
      </Helmet>
      <HeroSection />
      <WhyUs />
      <OurServices />
      <OurProjects />
      <OurCustomers />
    </HomeContainer>
  );
};

export default Home;
