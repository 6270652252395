import { MapContainer, MapFrame } from "./Map.styles";

const Map = () => {
  return (
    <MapContainer>
      <MapFrame
        title="Google map"
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API}
        &language=el&q=GAS+TEAM+ΚΑΤΑΣΚΕΥΑΣΤΙΚΗ+(Πολυζώης Απόστολος Δ.),Καλλιθέα+PVFC+3W`}
      ></MapFrame>
    </MapContainer>
  );
};

export default Map;
