// Helmet package
import { Helmet } from "react-helmet-async";

// Import styles
import { AboutUsContainer, SectionContainer } from "./AboutUs.styles";

//Import components
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import SectionHeader from "../../components/section-header/SectionHeader";

// Icons
import { ReactComponent as RightIcon } from "../../assets/svg/arrow_right_24.svg";

const breadcrumbLinks = [
  {
    id: 1,
    title: "Αρχική",
    route: "/",
  },
  {
    id: 2,
    title: "Εταιρεία",
    route: "/about-us",
  },
];

const AboutUs = () => {
  return (
    <AboutUsContainer>
      <Helmet>
        <title>Gas Team Κατασκευαστική | Σχτετικά με εμάς</title>
      </Helmet>
      <Breadcrumb links={breadcrumbLinks} />
      <SectionHeader title="ΠΟΙΟΙ ΕΙΜΑΣΤΕ" size="large" />
      <SectionContainer>
        <p>
          Η εταιρεία <span>Gasteam Τεχνική Κατασκευαστική </span>προσφέρει
          ολοκληρωμένες λύσεις σε ένα μεγάλο φάσμα ηλεκτρο-μηχανολογικών έργων
          και υπηρεσίων. Η &laquo;άρρητη γνώση&raquo; στο χώρο των κατασκευών
          περνάει από γενιά σε γενιά, από το 1981.
        </p>
        <p>
          Η εταιρεία παρέχει τη δυνατότητα σχεδίασης και υλοποίησης ενεργειακών
          και λοιπών σύνθετων μηχανολογικών έργων ανάμεσα στα οποία:
        </p>
        <ul>
          <li>
            <RightIcon />
            <p>Έργα εξοικονόμησης ενέργειας.</p>
          </li>
          <li>
            <RightIcon />
            <p>Ολοκληρωμένες εγκαταστάσεις τροφοδότησης Υγραερίου.</p>
          </li>
          <li>
            <RightIcon />
            <p>Ολοκληρωμένες εγκαταστάσεις τροφοδότησης Φυσικού Αερίου.</p>
          </li>
          <li>
            <RightIcon />
            <p>
              Εξειδικευμένα έργα (αποξηλώσεις, μεταφορές και αποσυναρμολογήσεις
              κάθε είδους μηχανήματος, συγκροτήσεις μονάδων, κατασκευές
              κυκλωμάτων κλπ).
            </p>
          </li>
          <li>
            <RightIcon />
            <p>Ολοκληρωμένες εγκαταστάσεις κυκλωμάτων τροφοδότησης ατμού.</p>
          </li>
          <li>
            <RightIcon />
            <p>Εγκαταστάσεις θέρμανσης με χρήση διαθερμικού λαδιού.</p>
          </li>
          <li>
            <RightIcon />
            <p>Εγκαταστάσεις ψύξης με τη χρήση αμμωνίας.</p>
          </li>
          <li>
            <RightIcon />
            <p>Εγκαταστάσεις ψύξης με τη χρήση μειγμάτων προπυλενογλυκόλης.</p>
          </li>
          <li>
            <RightIcon />
            <p>
              Εγκαταστάσεις κυκλωμάτων υγραερίου πλήρωσης περονοφόρων οχημάτων /
              αυτοκινήτων (autogas).
            </p>
          </li>
          <li>
            <RightIcon />
            <p>Εγκαταστάσεις κυκλωμάτων θέρμανσης-ηλιακών-γεωθερμίας.</p>
          </li>
          <li>
            <RightIcon />
            <p>Κατασκευές ανοξείδωτων δικτύων και δικτύων τροφίμων.</p>
          </li>
          <li>
            <RightIcon />
            <p>Έλεγχοι εγκαταστάσεων - δεξαμενών υγραερίου κλπ.</p>
          </li>
          <li>
            <RightIcon />
            <p>Πιστοποιήσεις</p>
          </li>
        </ul>
      </SectionContainer>
    </AboutUsContainer>
  );
};

export default AboutUs;
