// React
import { useState } from "react";

// Helmet package
import { Helmet } from "react-helmet-async";

//Import components
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import SectionHeader from "../../components/section-header/SectionHeader";
import ServiceItem from "./service-item/ServiceItem";

// Import styles
import {
  ServicesContainer,
  SectionContainer,
  ServicesListContainer,
  ImagesContainer,
} from "./Services.styles";

// Import data
import { ourServicesData } from "../../data";

const breadcrumbLinks = [
  {
    id: 1,
    title: "Αρχική",
    route: "/",
  },
  {
    id: 2,
    title: "Οι Υπηρεσίες μας",
    route: "/services",
  },
];

const Services = () => {
  const [isOpenArr, setIsOpenArr] = useState(
    Array(ourServicesData.length).fill(false)
  );

  const showServices = () => {
    return ourServicesData.map((s) => {
      return (
        <ServiceItem
          key={s.id}
          serviceId={s.id}
          title={s.title}
          type={s.type}
          content={s.content}
          isActive={isOpenArr[s.id]}
          toggleButton={toggleButtonHandler}
        ></ServiceItem>
      );
    });
  };

  const toggleButtonHandler = (index) => {
    const newIsOpenArr = [...isOpenArr];
    newIsOpenArr[index] = !isOpenArr[index];
    setIsOpenArr([...newIsOpenArr]);
  };

  return (
    <ServicesContainer>
      <Helmet>
        <title>Gas Team Κατασκευαστική | Οι Υπηρεσίες μας</title>
      </Helmet>
      <Breadcrumb links={breadcrumbLinks} />
      <SectionHeader title="ΟΙ ΥΠΗΡΕΣΙΕΣ ΜΑΣ" size="large" />
      <SectionContainer>
        <ServicesListContainer>{showServices()}</ServicesListContainer>
        <ImagesContainer>
          <img
            src={`${process.env.PUBLIC_URL}/images/our-projects/egkatastaseis-bioaerioy-15.webp`}
            alt={"Φωτογραφία έργου"}
          />
          <img
            src={`${process.env.PUBLIC_URL}/images/our-projects/diktya-trofimwn-25.webp`}
            alt={"Φωτογραφία έργου"}
          />
          <img
            src={`${process.env.PUBLIC_URL}/images/our-projects/egkatastaseis-fysikoy-aerioy-79.webp`}
            alt={"Φωτογραφία έργου"}
          />
        </ImagesContainer>
      </SectionContainer>
    </ServicesContainer>
  );
};

export default Services;
